<template>
  <div class="surfacewater-section1">
    <div class="headline-list d-flex align-items-center mt-5 mb-4">
      <span class="">ส่วนที่ 1 : </span>
      <span class="pl-2 weight-700">ข้อมูลทั่วไป</span>
    </div>
    <div class="blue-box mb-4 ans-1">
      <div class="header weight-700">1) ข้อมูลหมู่บ้าน</div>
      <div class="content">
        <div class="row">
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">จังหวัด</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="provinceList"
                  v-model="form.provinceId"
                  placeholder="จังหวัด"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                  @change="changeProvince($event)"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">อำเภอ</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="amphurList"
                  v-model="form.amphorId"
                  placeholder="อำเภอ"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                  @change="changeAmphur($event)"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ตำบล</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="tambolList"
                  v-model="form.tambolId"
                  placeholder="ตำบล"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                  @change="changeTambol($event)"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ชื่อหมู่บ้าน</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="villageList"
                  v-model="form.villageId"
                  placeholder="เลือกชื่อหมู่บ้าน"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                  @change="changeVillage($event)"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ชื่อระบบประปา</label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.systemPlumbingName"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">หมู่ที่</label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.villageNo"
                />
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">จำนวนประชากร</label>
              <div class="w-100">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="form.population"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ครัวเรือน</label>
              <div class="w-100">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="form.houseHold"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 my-4">
            เลือกกรอกค่าพิกัดที่ตั้ง เป็น UTM หรือ Lat/Long
          </div>
          <div class="col-12 col-sm-1"></div>
          <div class="col-6 col-sm-2">
            <div class="variable-data">
              <iconify
                icon="ant-design:caret-right-outlined"
                class="font-deeporange mr-3"
              />ค่า UTM
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">โซน </label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="zoneList"
                  v-model="form.utmZone"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">พิกัดหมู่บ้าน X </label>
              <div class="w-100">
                <input type="number" class="form-control" v-model="form.utme" />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">พิกัดหมู่บ้าน Y</label>
              <div class="w-100">
                <input type="number" class="form-control" v-model="form.utmn" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-1"></div>
          <div class="col-6 col-sm-2">
            <div class="variable-data">
              <iconify
                icon="ant-design:caret-right-outlined"
                class="font-deeporange mr-3"
              />
              ค่าละติจูด / ลองจิจูด
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">กำหนดค่าละติจูด </label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.latitude"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label"
                >กำหนดค่าลองจิจูด
              </label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.longitude"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-box mb-4 ans-2">
      <div class="header weight-700">2) ระบบประปาผิวดิน</div>
      <div class="content">
        <div class="row">
          <div class="col-6 col-md-4 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">อัตราการผลิต</label>
              <div class="w-100">
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="form.productionRate"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">ลบ.ม./ชม.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">รูปแบบของหน่วยงาน</label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.departmentModelType"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4 ans-3">
      <div class="header weight-700">3) พิกัดที่ตั้งแหล่งน้ำ</div>
      <div class="content">
        <div class="row">
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ค่าพิกัด UTM N(Y)</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.surefaceUTMN"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">E(X)</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.surefaceUTME"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">Zone</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="zoneList"
                  v-model="form.surefaceUTMZone"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3"></div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ค่าพิกัด Latitude</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.surefaceLatitude"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">Longitude</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.surefaceLongitude"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ปีที่สร้าง</label>
              <div class="w-100">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="form.surefaceCreateYear"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">โดยหน่วยงาน</label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.surefaceCreateDepartment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4 ans-4">
      <div class="header weight-700">4) พิกัดที่ตั้งระบบประปา</div>
      <div class="content">
        <div class="row">
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ค่าพิกัด UTM N(Y)</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.pwautmn"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">E(X)</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.pwautme"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">Zone</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="zoneList"
                  v-model="form.pwautmZone"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3"></div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ค่าพิกัด Latitude</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.pwaLatitude"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">Longitude</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.pwaLongitude"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ปีที่สร้าง</label>
              <div class="w-100">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="form.pwaCreateYear"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">โดยหน่วยงาน</label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.pwaCreateDepartment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4 ans-5">
      <div class="header weight-700">5) ชนิดของแหล่งน้ำ</div>
      <div class="content">
        <div class="d-flex align-items-center">
          <b-form-radio-group
            id=""
            v-model="form.waterSoureType"
            name="waterSoureType"
            class="my-radio"
          >
            <b-form-radio value="1" inline>ห้วย</b-form-radio>
            <b-form-radio value="2" inline>อ่างเก็บน้ำ</b-form-radio>
            <b-form-radio value="3" inline>แม่น้ำ</b-form-radio>
            <b-form-radio value="4" inline>บึง</b-form-radio>
            <b-form-radio value="5" inline>หนอง</b-form-radio>
            <b-form-radio value="6" inline>สระ</b-form-radio>
            <b-form-radio value="7" inline>คลอง</b-form-radio>
            <b-form-radio value="8" inline>เขื่อน</b-form-radio>
          </b-form-radio-group>

          <div class="align-items-center d-flex my-radio">
            <b-form-radio
              class="ans-5-1"
              v-model="form.waterSoureType"
              name="waterSoureType"
              value="9"
              unchecked-value=""
            >
              อื่นๆ ระบุ
            </b-form-radio>
            <input
              type="text"
              class="form-control"
              v-model="form.waterSourceTypeDescription"
              :disabled="form.waterSoureType != '9'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="blue-box mb-4 ans-6">
      <div class="header weight-700">6) แหล่งน้ำผิวดินของหน่วยงานใด</div>
      <div class="content">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                class="ans-6-1"
                v-model="form.ownerDepartment"
                name="ownerDepartment"
                value="1"
                unchecked-value=""
              >
                กรมชลประทาน
              </b-form-radio>
              <span>ระบุ ชนิดแหล่งน้ำและชื่อ</span>
              <input
                type="text"
                class="form-control w-25"
                v-model="ownerDepartmentDescriptionTemp[0].value"
                :disabled="form.ownerDepartment != '1'"
              />
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                class="ans-6-2"
                v-model="form.ownerDepartment"
                name="ownerDepartment"
                value="2"
                unchecked-value=""
              >
                กรมทรัพยากรน้ำ
              </b-form-radio>
              <span>ระบุ ชนิดแหล่งน้ำและชื่อ</span>
              <input
                type="text"
                class="form-control w-25"
                v-model="ownerDepartmentDescriptionTemp[1].value"
                :disabled="form.ownerDepartment != '2'"
              />
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                class="ans-6-3"
                v-model="form.ownerDepartment"
                name="ownerDepartment"
                value="3"
                unchecked-value=""
              >
                อบต./อบจ./เทศบาล
              </b-form-radio>
              <span>ระบุ ชนิดแหล่งน้ำและชื่อ</span>
              <input
                type="text"
                class="form-control w-25"
                v-model="ownerDepartmentDescriptionTemp[2].value"
                :disabled="form.ownerDepartment != '3'"
              />
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                class="ans-6-4"
                v-model="form.ownerDepartment"
                name="ownerDepartment"
                value="4"
                unchecked-value=""
              >
                แหล่งน้ำตามธรรมชาติ
              </b-form-radio>
              <span>ระบุ ชนิดแหล่งน้ำและชื่อ</span>
              <input
                type="text"
                class="form-control w-25"
                v-model="ownerDepartmentDescriptionTemp[3].value"
                :disabled="form.ownerDepartment != '4'"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                class="ans-6-other"
                v-model="form.ownerDepartment"
                name="ownerDepartment"
                value="5"
                unchecked-value=""
              >
                อื่นๆ
              </b-form-radio>
              <span>ระบุ</span>
              <input
                type="text"
                class="form-control w-25"
                v-model="ownerDepartmentDescriptionTemp[4].value"
                :disabled="form.ownerDepartment != '5'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4 ans-7" ref="managementBy_ScrollTo">
      <div class="header weight-700">7) ความจุแหล่งน้ำ (โดยประมาณ)</div>
      <div class="content">
        <div class="row">
          <div class="col-6 col-sm-3 align-items-center">
            <b-input-group append="เมตร">
              <template #prepend>
                <div class="input-group-text label-colon">กว้าง</div>
              </template>
              <input
                type="number"
                min="0"
                class="form-control"
                v-model="form.sourceWidth"
              />
            </b-input-group>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <b-input-group append="เมตร">
              <template #prepend>
                <div class="input-group-text label-colon">ยาว</div>
              </template>
              <input
                type="number"
                min="0"
                class="form-control"
                v-model="form.sourceHeight"
              />
            </b-input-group>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <b-input-group append="เมตร">
              <template #prepend>
                <div class="input-group-text label-colon">ลึก</div>
              </template>
              <input
                type="number"
                min="0"
                class="form-control"
                v-model="form.sourceDepth"
              />
            </b-input-group>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <b-input-group append="ลูกบาศก์เมตร">
              <template #prepend>
                <div class="input-group-text label-colon">ความจุ</div>
              </template>
              <input
                type="number"
                min="0"
                class="form-control"
                v-model="form.sourceCapacity"
              />
            </b-input-group>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4 ans-8">
      <div class="header weight-700">
        8) ระบบประปาแห่งนี้ บริหารโดย
        <span class="font-reddanger">(บังคับกรอก)</span>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                class="ans-8-1"
                v-model="form.managementBy"
                name="managementBy"
                value="1"
                unchecked-value=""
              >
                องค์การปกครองส่วนท้องถิ่น
              </b-form-radio>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                class="ans-8-2"
                v-model="form.managementBy"
                name="managementBy"
                value="2"
                unchecked-value=""
              >
                คณะกรรมการบริหารกิจการประปาหมู่บ้าน
              </b-form-radio>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                class="ans-8-3"
                v-model="form.managementBy"
                name="managementBy"
                value="3"
                unchecked-value=""
              >
                คณะกรรมการหมู่บ้าน
              </b-form-radio>
            </div>
          </div>
          <div class="col-12">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                class="ans-8-4"
                v-model="form.managementBy"
                name="managementBy"
                value="4"
                unchecked-value=""
              >
                อื่นๆ
              </b-form-radio>
              <span class="ml-2">ระบุ</span>
              <input
                type="text"
                class="form-control w-25"
                v-model="form.managementByDescription"
                :disabled="form.managementBy != '4'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4 ans-9">
      <div class="header weight-700">9) พื้นที่การให้บริการน้ำประปา</div>
      <div class="content">
        <div class="row">
          <div class="col-6 col-sm-4 align-items-center">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label" style="min-width: 280px"
                >ระบบประปาแห่งนี้ให้บริการน้ำ
              </label>
              <div class="w-100">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.serviceAreaTotalVillage"
                  />
                  <div class="input-group-append" style="width: 85px">
                    <span class="input-group-text">หมู่บ้าน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col align-items-center">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">หมู่ที่ </label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.serviceAreaTotalVillageNo"
                />
              </div>
            </div>
          </div>
          <div class="col align-items-center">
            <div class="d-flex form-group justify-content-end">
              <div class="col-10">
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="form.serviceAreaServeTotalHouseHold"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">ครัวเรือน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col align-items-center">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">จำนวน </label>
              <div class="w-100">
                <div class="d-flex">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="form.serviceAreaServeTotalPopulation"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">คน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="px-3 align-items-center">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label" style="min-width: 280px"
                >จำนวนผู้ใช้น้ำจากระบบประปาฯ แห่งนี้
              </label>
              <div class="w-100">
                <div class="d-flex">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    style="width: 200px"
                    v-model="form.serviceAreaTotalHouseHold"
                  />
                  <div class="input-group-append" style="width: 85px">
                    <span class="input-group-text">ครัวเรือน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto align-items-center">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">จำนวน </label>
              <div class="w-100">
                <div class="d-flex">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    style="width: 200px"
                    v-model="form.serviceAreaTotalPopulation"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">คน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col pl-0">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label" style="min-width: 280px"
                >รายได้เฉลี่ยต่อครัวเรือน
              </label>
              <div class="">
                <div class="input-group">
                  <input
                    style="min-width: 280px; width: 150px"
                    type="number"
                    class="form-control"
                    v-model="form.serviceAreaPopulationIncomeAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท/ปี</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4 ans-10">
      <div class="header weight-700">
        10) รายรับของกิจการระบบประปาฯ ในรอบ 1 ปี
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label text-right"
                >เฉลี่ยเดือนละ</label
              >
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceIncomeAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                    <span class="input-group-text">โดยได้จาก</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label"
                ><iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                เก็บค่าน้ำประปาในอัตราลูกบาศก์เมตรละ
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceIncomeSourceWaterBill"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                เก็บค่ารักษามาตรวัดน้ำ รายละ</label
              >
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceIncomeSourceMeterFee"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4 ans-11">
      <div class="header weight-700">
        11) รายจ่ายของกิจการระบบประปาฯ ในรอบ 1 ปี
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <span class="col-form-label text-right">เฉลี่ยเดือนละ</span>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    style="min-width: 280px"
                    class="form-control"
                    v-model="form.serviceOutcomeAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                    <span class="input-group-text"
                      >โดยมีค่าใช้จ่ายต่างๆ ดังนี้</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label"
                ><iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ค่าไฟฟ้าในรอบ 1 ปี เฉลี่ยเดือน
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomeElectricityBillAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ค่าตอบแทนเจ้าหน้าที่ผู้ปฏิบัติงาน ในรอบ 1 ปี
                เฉลี่ยเดือนละ</label
              >
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomeCompensationAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ค่าสารเคมี อาทิ สารส้ม ปูนขาว คลอรีน ฯลฯ ในรอบ 1 ปี
                เฉลี่ยนเดือนละ
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomeChemicalCostAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ค่าซ่อมแซมเครื่องสูบน้ำ ในรอบ 1 ปี เฉลี่ยเดือนละ
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomeMaintenanceCostAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ค่าซ่อมแซมท่อและอุปกรณ์ประปา ในรอบ 1 ปี เฉลี่ยเดือนละ
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomePumpMaintenanceCostAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                อื่นๆ (ระบุ)
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    style="min-width: 350px"
                    v-model="form.serviceOutcomeOtherDescription"
                  />
                </div>
              </div>
              <div class="ml-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">เป็นเงิน</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomeOther"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4 ans-12">
      <div class="header weight-700">
        12) การใช้ประโยชน์จากผลกำไรจากกิจการระบบประปาฯ ในช่วง 2 ปีที่ผ่านมา
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label text-right"
                >การใช้ประโยชน์จากผลกำไร
              </label>
              <div class="ml-3 align-items-center d-flex">
                <b-form-radio-group
                  class="my-radio"
                  v-model="form.utilizationUsableProfit"
                  name="utilizationUsableProfit"
                >
                  <b-form-radio class="col-4" value="1"> มี</b-form-radio>
                  <b-form-radio class="col-4" value="2">ไม่มี</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label"
                ><iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ขยายกิจการประปาเพิ่มสมาชิกผู้ใช้น้ำ
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.utilizationIncreaseMember"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">หลังคาเรือน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ปรับปรุงซ่อมแซมระบบประปา (ระบุ)
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    style="min-width: 350px"
                    v-model="form.utilizationMaintenanceDetail"
                  />
                </div>
              </div>
              <div class="ml-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">เป็นเงิน</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.utilizationMaintenanceCost"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                พัฒนาหมู่บ้านในโครงการด้านอื่นๆ (ระบุ)
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    style="min-width: 350px"
                    v-model="form.utilizationVillageDevelopmentDetail"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">เป็นเงิน</span>
                  </div>
                </div>
              </div>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.utilizationVillageDevelopmentCost"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                อื่นๆ (ระบุ)
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    style="min-width: 350px"
                    v-model="form.utilizationOtherDetail"
                  />
                </div>
              </div>
              <div class="ml-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">เป็นเงิน</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.utilizationOtherCost"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-total mt-5 mb-4 d-flex ans-13">
      <div class="d-flex align-items-center px-3 w-100">
        <div class="">
          <div class="pl-2 weight-700">
            ขณะนี้ประปาหมู่บ้านมีเงินเหลือสะสมสำหรับกิจการระบบประปา
          </div>
        </div>
        <div class="ml-5">
          <div class="d-flex">
            <label for="input" class="col-form-label mr-3">เป็นเงิน</label>
            <div class="">
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  style="min-width: 280px"
                  v-model="form.utilizationRemainingBudget"
                />
                <div class="input-group-append">
                  <span class="input-group-text">บาท</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-4 text-center">
        <button type="button" class="btn btn-back" @click="prevCurSection">
          ย้อนกลับ
        </button>
      </div>
      <div class="col-4 text-center">
        <button type="button" class="btn btn-submit" @click="nextCurSection">
          ถัดไป
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterService } from "@/services/main.service.js";

export default {
  name: "surfacewater-section1",
  props: {
    formDetail: {
      type: Object,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => null,
    },
    toReload: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      fromquery: null,
      zoneList: [],
      provinceList: [],
      amphurList: [],
      tambolList: [],
      villageList: [],

      ownerDepartmentDescriptionTemp: [
        { value: null },
        { value: null },
        { value: null },
        { value: null },
        { value: null },
      ],

      form: {
        villageId: null, //ชื่อหมู่บ้าน id
        systemPlumbingName: null, //ชื่อระบบประปา
        villageNo: null, //หมู่ที่
        tambolId: null, //ตำบล id
        amphorId: null, //อำเภอ id
        provinceId: null, //จังหวัด id
        population: null, //จำนวนประชากร int
        houseHold: null, //ครัวเรือน int
        utmZone: null, //โซน
        utme: null, //พิกัดหมู่บ้าน X
        utmn: null, //พิกัดหมู่บ้าน Y
        latitude: null, //กำหนดค่าละติจูด
        longitude: null, //กำหนดค่าลองจิจูด
        // 2) ระบบประปาผิวดิน
        productionRate: null, //อัตราการผลิต
        departmentModelType: null, //รูปแบบของหน่วยงาน
        // 3) พิกัดที่ตั้งแหล่งน้ำ                    DB -> QualitySurveySurefaceWater
        surefaceUTMZone: null, // Zone
        surefaceUTME: null, // E(X)
        surefaceUTMN: null, // ค่าพิกัด UTM N(Y)
        surefaceLatitude: null, // ค่าพิกัด Latitude
        surefaceLongitude: null, // Longitude
        surefaceCreateYear: null, // ปีที่สร้าง
        surefaceCreateDepartment: null, // โดยหน่วยงาน
        // 4) พิกัดที่ตั้งระบบประปา                 DB -> QualitySurvey
        pwautmZone: null, // Zone
        pwautme: null, // E(X)
        pwautmn: null, // ค่าพิกัด UTM N(Y)
        pwaLatitude: null, // ค่าพิกัด Latitude
        pwaLongitude: null, // Longitude
        pwaCreateYear: null, // ปีที่สร้าง
        pwaCreateDepartment: null, // โดยหน่วยงาน
        // 5) ชนิดของแหล่งน้ำ
        waterSoureType: null,
        waterSourceTypeDescription: null, // กรณีเลือก แหล่งน้ำผิวดินเป็นหน่วยงาน อื่นๆ
        // 6) แหล่งน้ำผิวดินของหน่วยงานใด
        ownerDepartment: null,
        ownerDepartmentDescription: null, // กรณีเลือก แหล่งน้ำผิวดินเป็นหน่วยงาน อื่นๆ
        // 7) ความจุแหล่งน้ำ (โดยประมาณ)
        sourceWidth: null,
        sourceHeight: null,
        sourceDepth: null,
        sourceCapacity: null,
        // 8) ระบบประปาแห่งนี้ บริหารโดย
        managementBy: null,
        managementByDescription: null, // กรณีที่ระบุ ระบบประปานี้บริหารโดยหน่วยงานอื่น
        // 9) พื้นที่การให้บริการน้ำประปา
        serviceAreaTotalVillage: null, //ระบบประปาแห่งนี้ให้บริการน้ำ , หมู่บ้าน
        serviceAreaTotalVillageNo: null, //หมู่ที่
        serviceAreaServeTotalHouseHold: null, //ครัวเรือน
        serviceAreaServeTotalPopulation: null, //คน
        serviceAreaTotalHouseHold: null, //จำนวนผู้ใช้น้ำจากระบบประปาฯ แห่งนี้, ครัวเรือน
        serviceAreaTotalPopulation: null, //จำนวน, คน
        serviceAreaPopulationIncomeAverage: null, //รายได้เฉลี่ยต่อครัวเรือน
        // 10) รายรับของกิจการระบบประปาฯ ในรอบ 1 ปี
        serviceIncomeAverage: null, //เฉลี่ยเดือนละ
        serviceIncomeSourceWaterBill: null, //เก็บค่าน้ำประปาในอัตราลูกบาศก์เมตรละ
        serviceIncomeSourceMeterFee: null, //ก็บค่ารักษามาตรวัดน้ำ รายละ
        // 11) รายจ่ายของกิจการระบบประปาฯ ในรอบ 1 ปี
        serviceOutcomeAverage: null, //เฉลี่ยเดือนละ
        serviceOutcomeElectricityBillAverage: null, //ค่าไฟฟ้าในรอบ 1 ปี เฉลี่ยเดือน, บาท
        serviceOutcomeCompensationAverage: null, //ค่าตอบแทนเจ้าหน้าที่ผู้ปฏิบัติงาน ในรอบ 1 ปี เฉลี่ยเดือนละ, บาท
        serviceOutcomeChemicalCostAverage: null, //ค่าสารเคมี อาทิ สารส้ม ปูนขาว คลอรีน ฯลฯ ในรอบ1 ปี เฉลี่ยนเดือนละ, บาท
        serviceOutcomeMaintenanceCostAverage: null, //ค่าซ่อมแซมเครื่องสูบน้ำ ในรอบ 1 ปี เฉลี่ยเดือนละ, บาท
        serviceOutcomePumpMaintenanceCostAverage: null, //ค่าซ่อมแซมท่อและอุปกรณ์ประปา ในรอบ 1 ปี เฉลี่ยเดือนละ, บาท
        serviceOutcomeOtherDescription: null, // อื่นๆ (ระบุ)
        serviceOutcomeOther: null,
        // 12) การใช้ประโยชน์จากผลกำไรจากกิจการระบบประปาฯ ในช่วง 2 ปีที่ผ่านมา
        utilizationUsableProfit: 1, //การใช้ประโยชน์จากผลกำไร, มี ไม่มี
        utilizationIncreaseMember: null, //ขยายกิจการประปาเพิ่มสมาชิกผู้ใช้น้ำ, หลังคาเรือน
        utilizationMaintenanceDetail: null, //ปรับปรุงซ่อมแซมระบบประปา (ระบุ)
        utilizationMaintenanceCost: null, //เป็นเงิน, บาท
        utilizationVillageDevelopmentDetail: null, //พัฒนาหมู่บ้านในโครงการด้านอื่นๆ (ระบุ)
        utilizationVillageDevelopmentCost: null, //เป็นเงิน, บาท
        utilizationOtherDetail: null, //อื่นๆ (ระบุ)
        utilizationOtherCost: null, //เป็นเงิน, บาท

        utilizationRemainingBudget: null,
      },
    };
  },
  async created() {
    window._surface1 = this;

    await this.getZone();
    await this.getProvince();
  },
  methods: {
    prevCurSection() {
      //goback to list
      this.$router.push({
        name: "AssessmentList",
      });
    },
    nextCurSection() {
      if (this.form.managementBy === null) {
        this.$refs["managementBy_ScrollTo"].scrollIntoView({
          behavior: "smooth",
        });
        return;
      } else if (this.form.managementBy == 4 && !this.managementByDescription) {
        // this.$refs["managementBy_ScrollTo"].scrollIntoView({
        //   behavior: "smooth",
        // });
        // return;
      }

      this.$emit("updateCurSection", 2);
      this.$emit("updateDetail", this.computeResult());
      window.scrollTo(0, 0);
    },
    computeResult() {
      //ownerDepartmentDescriptionTemp

      //set ownerDepartmentDescription
      if (this.form.ownerDepartment) {
        const number = Number(this.form.ownerDepartment);

        this.form.ownerDepartmentDescription =
          this.ownerDepartmentDescriptionTemp[number - 1].value;
      } else {
        this.form.ownerDepartmentDescription = null;
      }

      return { ...this.form };
    },
    getQueryString() {
      const query = { ...this.$route.query };
      this.fromquery = query?.fromquery;
      if (this.fromquery === 1) {
        this.bindFirstData(query);
      }
    },
    async bindFirstData(data) {
      this.form.provinceId = data.pro;
      this.form.amphorId = data.amp;
      this.form.tambolId = data.tam;
      this.form.villageId = data.village;
      this.form.utmZone = data.zone;
      this.form.utme = data.utme;
      this.form.utmn = data.utmn;
      this.form.latitude = data.latitude;
      this.form.longitude = data.longitude;
      this.form.systemPlumbingName = data.systemName;

      if (this.form.provinceId) {
        await this.getAmphur(this.form.provinceId);

        if (this.form.amphorId) {
          await this.getTambol(this.form.amphorId);

          if (this.form.tambolId) {
            await this.getVillage(this.form.tambolId);

            if (this.form.villageId) {
              this.form.villageNo = data.moo;
            }
          }
        }
      }
    },
    async bindSurveyDetail() {
      const surface = this.formDetail.qualitySurveySurefaceWater;

      this.form = {
        ...this.formDetail,
        systemPlumbingName: this.formDetail.name,
        pwaCreateYear: this.formDetail.createYear,
        pwaCreateDepartment: this.formDetail.createDepartment,

        // 2) ระบบประปาผิวดิน
        productionRate: surface.productionRate, //อัตราการผลิต
        departmentModelType: surface.departmentModelType, //รูปแบบของหน่วยงาน

        // 3) พิกัดที่ตั้งแหล่งน้ำ
        surefaceUTMZone: surface.utmZone, // Zone
        surefaceUTME: surface.utme, // E(X)
        surefaceUTMN: surface.utmn, // ค่าพิกัด UTM N(Y)
        surefaceLatitude: surface.latitude, // ค่าพิกัด Latitude
        surefaceLongitude: surface.longitude, // Longitude
        surefaceCreateYear: surface.createYear, // ปีที่สร้าง
        surefaceCreateDepartment: surface.createDepartment, // โดยหน่วยงาน

        // 5) ชนิดของแหล่งน้ำ
        waterSoureType: surface.waterSoureType,
        waterSourceTypeDescription: surface.waterSourceTypeDescription,

        // 6) แหล่งน้ำผิวดินของหน่วยงานใด
        ownerDepartment: surface.ownerDepartment
          ? surface.ownerDepartment + ""
          : null,
        ownerDepartmentDescription: surface.ownerDepartmentDescription,

        // 7) ความจุแหล่งน้ำ (โดยประมาณ)
        sourceWidth: surface.sourceWidth,
        sourceHeight: surface.sourceHeight,
        sourceDepth: surface.sourceDepth,
        sourceCapacity: surface.sourceCapacity,
      };

      const ownerDepartmentIndex = this.form.ownerDepartment || null;
      if (ownerDepartmentIndex !== null) {
        this.ownerDepartmentDescriptionTemp[
          parseInt(ownerDepartmentIndex) - 1
        ] = {
          value: this.form.ownerDepartmentDescription,
        };
      }

      if (this.form.provinceId) {
        await this.getAmphur(this.form.provinceId);

        if (this.form.amphorId) {
          await this.getTambol(this.form.amphorId);

          if (this.form.tambolId) {
            await this.getVillage(this.form.tambolId);
          }
        }
      }
    },
    async getZone() {
      this.zoneList = await FilterService.getListZone().then((res) => {
        return res;
      });
    },
    async getProvince() {
      await FilterService.getListProvince()
        .then((res) => {
          this.provinceList = res.data;
        })
        .catch((err) => {
          this.provinceList = [];
        });
    },
    async getAmphur(provinceId = null, id = null) {
      await FilterService.getListAmphur(provinceId)
        .then((res) => {
          this.amphurList = res.data;
        })
        .catch((err) => {
          this.amphurList = [];
        });
    },
    async getTambol(amphorId = null, id = null) {
      await FilterService.getListTambol(amphorId)
        .then((res) => {
          this.tambolList = res.data;
        })
        .catch((err) => {
          this.tambolList = [];
        });
    },
    async getVillage(tambolId = null, id = null) {
      await FilterService.getListVillage(tambolId)
        .then((res) => {
          this.villageList = res.data;
        })
        .catch((err) => {
          this.villageList = [];
        });
    },
    changeProvince(event) {
      const id = event;

      //clear
      this.form.amphorId = null;
      this.form.tambolId = null;
      this.tambolList = [];
      this.form.villageId = null;
      this.villageList = [];
      this.form.villageNo = null;

      this.getAmphur(id);
    },
    changeAmphur(event) {
      const id = event;

      //clear
      this.form.tambolId = null;
      this.form.villageId = null;
      this.villageList = [];
      this.form.villageNo = null;

      this.getTambol(id);
    },
    changeTambol(event) {
      const id = event;
      //clear
      this.form.villageId = null;
      this.form.villageNo = null;

      this.getVillage(id);
    },
    changeVillage(event) {
      const village = this.villageList.find((f) => f.id == event);
      this.form.villageNo = village?.villageNo || null;
    },
    checkModeAndBind() {
      //check mode
      if (this.mode === "edit") {
        //get form detail
        this.bindSurveyDetail();
      } else {
        this.getQueryString();
      }
    },
  },
  watch: {
    toReload(val, oldVal) {
      if (val) {
        this.checkModeAndBind();
      }
    },
  },
};
</script>

<style lang="scss">
.surfacewater-section1 {
  .ans-1,
  .ans-2,
  .ans-3,
  .ans-4,
  .ans-5,
  .ans-6,
  .ans-7,
  .ans-8,
  .ans-9 {
    .form-group {
      > label {
        margin-bottom: 0;
        &:after {
          content: ":";
          margin: 0 0.75rem;
        }
      }
    }
  }

  .ans-1 {
    .form-group {
      > label {
        min-width: 180px;
        max-width: 375px;
        text-align: right;
      }
    }
  }

  .ans-2,
  .ans-3,
  .ans-4 {
    .form-group {
      > label {
        min-width: 170px;
        max-width: 170px;
        text-align: right;
      }
    }
  }

  .ans-5-1 {
    label {
      width: max-content;
      padding-right: 10px;
    }
  }

  .ans-6 {
    label {
      width: 160px;
    }

    span {
      width: 200px;
      margin-right: 10px;
    }

    input[type="text"] {
      max-width: 345px;
    }
  }

  .ans-8 {
    span {
      margin-right: 10px;
    }

    input[type="text"] {
      max-width: 345px;
    }
  }

  .ans-9 {
    .form-group {
      > label {
        min-width: 120px;
        max-width: 120px;
        text-align: right;
      }
    }
  }
}
</style>
