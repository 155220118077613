<template>
  <div class="surfacewater-section2">
    <div class="headline-list d-flex align-items-center mt-5 mb-4">
      <span class="">ส่วนที่ 2 : </span>
      <span class="pl-2 weight-700"
        >หลักเกณฑ์และมาตรฐานการประเมินคุณภาพระบบประปาหมู่บ้าน</span
      >
    </div>

    <div class="box-shadow-soft mb-4">
      <div class="pl-2 weight-700">
        หลักเกณฑ์และมาตรฐานการประเมินคุณภาพระบบประปาหมู่บ้าน
      </div>
      <div>
        <div class="mt-4">
          <div class="d-flex justify-content-around">
            <div class="d-flex align-items-center">
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  icon-number
                "
                :class="{
                  complete: currentQuestion > 1,
                  active: currentQuestion === 1,
                }"
              >
                1
              </div>
              <div class="step-txt pl-3">ด้านแหล่งน้ำดิบ</div>
            </div>
            <div class="d-flex align-items-center">
              <img src="/images/Line.png" style="height: 1 px" />
            </div>
            <div class="d-flex align-items-center">
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  icon-number
                "
                :class="{
                  complete: currentQuestion > 2,
                  active: currentQuestion === 2,
                }"
              >
                2
              </div>
              <div class="step-txt pl-3">ด้านประปา</div>
            </div>
            <div class="d-flex align-items-center">
              <img src="/images/Line.png" style="height: 1 px" />
            </div>
            <div class="d-flex align-items-center">
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  icon-number
                "
                :class="{
                  complete: currentQuestion > 3,
                  active: currentQuestion === 3,
                }"
              >
                3
              </div>
              <div class="step-txt pl-3">
                ด้านการควบคุมการผลิต <br />และบำรุงรักษาระบบประปา
              </div>
            </div>
            <div class="d-flex align-items-center">
              <img src="/images/Line.png" style="height: 1 px" />
            </div>
            <div class="d-flex align-items-center">
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  icon-number
                "
                :class="{
                  complete: currentQuestion > 4,
                  active: currentQuestion === 4,
                }"
              >
                4
              </div>
              <div class="step-txt pl-3">
                ด้านปริมาณ แรงดันน้ำ <br />และคุณภาพน้ำประปา
              </div>
            </div>
            <div class="d-flex align-items-center">
              <img src="/images/Line.png" style="height: 1 px" />
            </div>
            <div class="d-flex align-items-center">
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  icon-number
                "
                :class="{
                  complete: currentQuestion > 5,
                  active: currentQuestion === 5,
                }"
              >
                5
              </div>
              <div class="step-txt pl-3">
                ด้านการบริหารกิจการ<br />
                ระบบประปา
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4" v-show="currentQuestion === 1">
      <div class="step-1 weight-700">1) ด้านแหล่งน้ำดิบ</div>
      <div class="step-2">
        1.1) ในรอบ 3 ปีที่ผ่านมา มีการขาดแคลนน้ำดิบสำหรับผลิตน้ำประปา
        ในแต่ละปีสูงสุดนานกี่เดือน
      </div>
      <div class="content sec2-q1">
        <div class="row px-3">
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                v-model="
                  form.filter((f) => f.surveyQuestionId === 1)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q1"
                value="1"
                unchecked-value=""
                @change="clearInput1_1(1)"
              >
                ก. ไม่ขาดแคลน
              </b-form-radio>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                v-model="
                  form.filter((f) => f.surveyQuestionId === 1)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q1"
                value="2"
                unchecked-value=""
                @change="clearInput1_1(2)"
              >
                ข. ขาดแคลน 1 ปี
              </b-form-radio>
              <span>ช่วงเดือน โปรดระบุ</span>
              <input
                type="text"
                class="form-control w-25"
                v-model="choiceValueTempQ1_2"
                :disabled="
                  form.filter((f) => f.surveyQuestionId === 1)[0]
                    .surveyQuestionChoiceId != '2'
                "
              />
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                v-model="
                  form.filter((f) => f.surveyQuestionId === 1)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q1"
                value="3"
                unchecked-value=""
                @change="clearInput1_1(3)"
              >
                ค. ขาดแคลน 2 ปี
              </b-form-radio>
              <span>ช่วงเดือน โปรดระบุ</span>
              <input
                type="text"
                class="form-control w-25"
                v-model="choiceValueTempQ1_3"
                :disabled="
                  form.filter((f) => f.surveyQuestionId === 1)[0]
                    .surveyQuestionChoiceId != '3'
                "
              />
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                v-model="
                  form.filter((f) => f.surveyQuestionId === 1)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q1"
                value="4"
                unchecked-value=""
                @change="clearInput1_1(4)"
              >
                ง. ขาดแคลน 3 ปี
              </b-form-radio>
              <span>ช่วงเดือน โปรดระบุ</span>
              <input
                type="text"
                class="form-control w-25"
                v-model="choiceValueTempQ1_4"
                :disabled="
                  form.filter((f) => f.surveyQuestionId === 1)[0]
                    .surveyQuestionChoiceId != '4'
                "
              />
            </div>
          </div>
          <div class="col-12">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                v-model="
                  form.filter((f) => f.surveyQuestionId === 1)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q1"
                value="5"
                unchecked-value=""
                @change="clearInput1_1(5)"
              >
                จ. ขาดแคลนมากกว่า 3 ปี
              </b-form-radio>
              <span>ช่วงเดือน โปรดระบุ</span>
              <input
                type="text"
                class="form-control w-25"
                v-model="choiceValueTempQ1_5"
                :disabled="
                  form.filter((f) => f.surveyQuestionId === 1)[0]
                    .surveyQuestionChoiceId != '5'
                "
              />
            </div>
          </div>
        </div>
      </div>

      <div class="step-2">1.2) มีแหล่งน้ำดิบสำรองสำหรับผลิตน้ำประปาหรือไม่</div>
      <div class="content sec2-q2">
        <div class="row px-3">
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                v-model="
                  form.filter((f) => f.surveyQuestionId === 2)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q2"
                value="1"
                unchecked-value=""
                @change="clearInput1_2(1)"
              >
                ก. มี เพียงพอ
              </b-form-radio>
              <div class="align-items-center d-flex">
                <span class="flex-shrink-0 mr-2">ชนิดของแหล่งน้ำ</span>
                <input
                  type="text"
                  class="form-control input-width-1"
                  v-model="choiceValueTempQ2_1_1"
                  :disabled="
                    form.filter((f) => f.surveyQuestionId === 2)[0]
                      .surveyQuestionChoiceId != '1'
                  "
                />
              </div>
              <div class="align-items-center d-flex ml-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">ปริมาณน้ำ</span>
                  </div>
                  <input
                    type="number"
                    min="0"
                    v-model="choiceValueTempQ2_1_2"
                    class="form-control input-width-2"
                    :disabled="
                      form.filter((f) => f.surveyQuestionId === 2)[0]
                        .surveyQuestionChoiceId != '1'
                    "
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">ลบ.ม./ซม.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                v-model="
                  form.filter((f) => f.surveyQuestionId === 2)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q2"
                value="2"
                unchecked-value=""
                @change="clearInput1_2(2)"
              >
                ข. มี ไม่เพียงพอ
              </b-form-radio>
              <div class="align-items-center d-flex">
                <span class="flex-shrink-0 mr-2">ชนิดของแหล่งน้ำ </span>
                <input
                  type="text"
                  class="form-control input-width-1"
                  v-model="choiceValueTempQ2_2_1"
                  :disabled="
                    form.filter((f) => f.surveyQuestionId === 2)[0]
                      .surveyQuestionChoiceId != '2'
                  "
                />
              </div>
              <div class="align-items-center d-flex ml-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">ปริมาณน้ำ</span>
                  </div>
                  <input
                    type="number"
                    min="0"
                    v-model="choiceValueTempQ2_2_2"
                    class="form-control input-width-2"
                    :disabled="
                      form.filter((f) => f.surveyQuestionId === 2)[0]
                        .surveyQuestionChoiceId != '2'
                    "
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">ลบ.ม./ซม.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="align-items-center d-flex my-radio">
              <b-form-radio
                v-model="
                  form.filter((f) => f.surveyQuestionId === 2)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q2"
                value="3"
                unchecked-value=""
                @change="clearInput1_2(3)"
              >
                ค. ไม่มี แหล่งน้ำดิบสำรองสำหรับผลิตน้ำประปา
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>
      <div class="step-2">1.3) คุณภาพน้ำดิบเบื้องต้น</div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio mb-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 3)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q3"
              inline
            >
              <label for="" class="mr-5">1.3.1)</label>
              <b-form-radio value="1" style="width: 140px"
                >ก. ขุ่นน้อย</b-form-radio
              >
              <b-form-radio value="2" style="width: 140px"
                >ข. ขุ่นมาก</b-form-radio
              >
            </b-form-radio-group>
            <b-form-radio-group
              class="my-radio mb-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 4)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q4"
              inline
            >
              <label for="" class="mr-5">1.3.2)</label>
              <b-form-radio value="1" style="width: 140px"
                >ก. ไม่มีกลิ่น</b-form-radio
              >
              <b-form-radio value="2" style="width: 140px"
                >ข. มีกลิ่น</b-form-radio
              >
            </b-form-radio-group>
            <b-form-radio-group
              class="my-radio mb-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 5)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q5"
              inline
            >
              <label for="" class="mr-5">1.3.3)</label>
              <b-form-radio value="1" style="width: 140px">ก. จืด</b-form-radio>
              <b-form-radio value="2" style="width: 140px"
                >ข. กร่อย , เค็ม
              </b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group
              class="my-radio mb-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 6)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q6"
              inline
            >
              <label for="" class="mr-5">1.3.4)</label>
              <b-form-radio value="1" style="width: 140px"
                >ก. ไม่เปรี้ยว</b-form-radio
              >
              <b-form-radio value="2" style="width: 140px"
                >ข. เปรี้ยว</b-form-radio
              >
            </b-form-radio-group>
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 7)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q7"
              inline
            >
              <label for="" class="mr-5">1.3.5)</label>
              <b-form-radio value="1" style="width: 140px"
                >ก. ไม่กระด้าง</b-form-radio
              >
              <b-form-radio value="2" style="width: 140px">
                ข. กระด้าง</b-form-radio
              >
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4" v-show="currentQuestion === 2">
      <div class="step-1 weight-700">2) ด้านระบบประปา</div>

      <div class="step-2">2.1 ระบบน้ำดิบ</div>
      <div class="step-3">
        <span class="mr-2"> 2.1.1)</span>
        เครื่องสูบน้ำดิบและจำนวนเครื่องสูบน้ำดิบ (เลือกตอบเพียงข้อเดียว)
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="sec2q8_9"
              name="sec2q8_9"
              stacked
            >
              <b-form-radio class="mb-3" value="1" @change="clearAnswer2_1_1(1)"
                >มี 1 ชุด</b-form-radio
              >
              <b-form-radio-group
                class="my-radio mb-3 pl-3"
                v-model="
                  form.filter((f) => f.surveyQuestionId === 8)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q8"
                stacked
              >
                <b-form-radio
                  class="mb-3"
                  value="1"
                  :disabled="sec2q8_9 !== '1'"
                  >ก. ใช้งานได้</b-form-radio
                >
                <b-form-radio value="2" :disabled="sec2q8_9 !== '1'"
                  >ข. ใช้งานไม่ได้</b-form-radio
                >
              </b-form-radio-group>
              <b-form-radio class="mb-3" value="2" @change="clearAnswer2_1_1(2)"
                >มี 2 ชุด</b-form-radio
              >
              <b-form-radio-group
                class="my-radio pl-3"
                v-model="
                  form.filter((f) => f.surveyQuestionId === 9)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q9"
                stacked
              >
                <b-form-radio
                  class="mb-3"
                  value="1"
                  :disabled="sec2q8_9 !== '2'"
                  >ก. ใช้งานได้ 2 ชุด</b-form-radio
                >
                <b-form-radio
                  class="mb-3"
                  value="2"
                  :disabled="sec2q8_9 !== '2'"
                  >ข. ใช้งานได้ 1 ชุด ใช้ไม่ได้ 1 ชุด</b-form-radio
                >
                <b-form-radio value="3" :disabled="sec2q8_9 !== '2'"
                  >ค. ใช้ไม่ได้ทั้ง 2 ชุด</b-form-radio
                >
              </b-form-radio-group>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 2.1.2)</span> ตู้ควบคุมเครื่องสูบน้ำดิบ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 10)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q10"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/อุปกรณ์ภายในตู้ไม่ครบ)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 2.1.3)</span> โรงสูบน้ำดิบ
        (แบบติดตั้งบนดินหรือแบบแพลอย)
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 11)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q11"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3"><span class="mr-2"> 2.1.4)</span> ท่อส่งน้ำดิบ</div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 12)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q12"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. สภาพดี</b-form-radio>
              <b-form-radio value="2">ข. สภาพชำรุด รั่วซึม</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-2">2.2 ระบบผลิตน้ำ</div>
      <div class="step-3">
        <span class="mr-2"> 2.2.1)</span>
        กำลังการผลิตมีขนาดเพียงพอต่อการใช้น้ำของผู้ใช้น้ำหรือไม่
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 13)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q13"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. เพียงพอ</b-form-radio>
              <b-form-radio value="2">ข. ไม่เพียงพอ</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.2.2)</span>
        จำนวนชั่วโมงการผลิตน้ำประปา
        (ชั่วโมงการทำงานของเครื่องสูบน้ำดิบในแต่ละวัน)
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 14)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q14"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ผลิตน้ำไม่เกิน 14 ชม. /วัน
              </b-form-radio>
              <b-form-radio value="2"
                >ข. ผลิตน้ำมากกว่า 14 ชม. /วัน</b-form-radio
              >
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.2.3)</span>
        ระบบสร้างตะกอน ระบบรวมตะกอน ระบบตกตะกอน
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 15)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q15"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/รั่วซึม)
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 2.2.4)</span>
        ประตูน้ำระบบสร้างตะกอน ระบบรวมตะกอน ระบบตกตะกอน
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 16)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q16"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ใช้งานได้ทุกตัว</b-form-radio
              >
              <b-form-radio class="mb-3" value="2"
                >ข. ใช้งานได้บางตัว</b-form-radio
              >
              <b-form-radio value="3">ค. ใช้งานไม่ได้ทุกตัว</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 2.2.5)</span>
        ระบบถังกรอง
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 17)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q17"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/รั่วซึม)</b-form-radio
              >
              <b-form-radio value="3">ค. มี ไม่ได้ใช้งาน / ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.2.6)</span>
        ประตูน้ำระบบถังกรอง
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 18)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q18"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ใช้งานได้ทุกตัว</b-form-radio
              >
              <b-form-radio class="mb-3" value="2"
                >ข. ใช้งานได้บางตัว</b-form-radio
              >
              <b-form-radio value="3">ค. ใช้งานไม่ได้ทุกตัว</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.2.7)</span>
        ทรายกรอง หรือสารกรองชนิดอื่น
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 19)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q19"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ไม่สามารถกรองน้ำได้)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี หรือตรวจสอบไม่ได้</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.2.8)</span>
        ถังน้ำใส
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 20)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q20"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/รั่วซึม)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.2.9)</span>
        ป้ายหรืออุปกรณ์บอกปริมาตรน้ำในถังน้ำใส
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 21)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q21"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม
                (ชำรุด/อ่านค่าปริมาตรน้ำไม่ได้)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 2.2.10)</span>
        ฝาปิดทางขึ้น-ลง ถังน้ำใส
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio pl-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 22)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q22"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/ปิดฝาไม่ได้)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 2.2.11)</span>
        รางระบายตะกอน
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio pl-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 23)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q23"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/รั่วซึม/อุดตัน)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 2.2.12)</span>
        สระพักตะกอน
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio pl-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 24)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q24"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/รั่วซึม)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 2.2.13)</span>
        ระบบจ่ายสารเคมีและฆ่าเชื้อโรค
      </div>
      <div class="step-4">
        <span class="mr-2">2.2.13.1)</span>
        ระบบจ่ายสารส้มหรือสารอื่นที่ช่วยในการตกตะกอนของน้ำดิบ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio pl-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 25)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q25"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. มี สภาพดี ใช้งานได้</b-form-radio
              >
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/รั่วซึม)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-4">
        <span class="mr-2">2.2.13.2)</span>
        ระบบจ่ายปูนขาวหรือสารอื่นที่ช่วยปรับค่า pH ของน้ำดิบ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio pl-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 26)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q26"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/รั่วซึม)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-4">
        <span class="mr-2">2.2.13.3)</span>
        ระบบจ่ายสารละลายคลอรีนหรือสารอื่นที่ใช้ในการฆ่าเชื้อโรค
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio pl-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 27)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q27"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/รั่วซึม)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-4">
        <span class="mr-2">2.2.13.4)</span>
        เครื่องมือตรวจวัดความเป็นกรด-ด่างในน้ำ (pH)
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio pl-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 28)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q28"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. มี สภาพดี ใช้งานได้</b-form-radio
              >
              <b-form-radio class="mb-3" value="2"
                >ข. มี ใช้งานไม่ได้ หรือ ไม่มีสารเคมี</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-4">
        <span class="mr-2">2.2.13.5)</span>
        เครื่องวิเคราะห์คลอรีนหลงเหลือ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio pl-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 29)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q29"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. มี สภาพดี ใช้งานได้</b-form-radio
              >
              <b-form-radio class="mb-3" value="2"
                >ข. มี ใช้งานไม่ได้ หรือ ไม่มีสารเคมี</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-2">2.3 ระบบจ่ายน้ำ</div>
      <div class="step-3">
        <span class="mr-2"> 2.3.1)</span>
        เครื่องสูบน้ำดีและจำนวนเครื่องสูบน้ำดี (เลือกตอบเพียงข้อเดียว)
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="sec2q30_31"
              name="sec2q30_31"
              stacked
            >
              <b-form-radio class="mb-3" value="1" @change="clearAnswer2_3_1(1)"
                >มี 1 ชุด</b-form-radio
              >
              <b-form-radio-group
                class="my-radio mb-3 pl-3"
                v-model="
                  form.filter((f) => f.surveyQuestionId === 30)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q30"
                stacked
              >
                <b-form-radio
                  class="mb-3"
                  value="1"
                  :disabled="sec2q30_31 !== '1'"
                  >ก. ใช้งานได้</b-form-radio
                >
                <b-form-radio value="2" :disabled="sec2q30_31 !== '1'"
                  >ข. ใช้งานไม่ได้</b-form-radio
                >
              </b-form-radio-group>
              <b-form-radio class="mb-3" value="2" @change="clearAnswer2_3_1(2)"
                >มี 2 ชุด</b-form-radio
              >
              <b-form-radio-group
                class="my-radio pl-3"
                v-model="
                  form.filter((f) => f.surveyQuestionId === 31)[0]
                    .surveyQuestionChoiceId
                "
                name="sec2q31"
                stacked
              >
                <b-form-radio
                  class="mb-3"
                  value="1"
                  :disabled="sec2q30_31 !== '2'"
                  >ก. ใช้งานได้ 2 ชุด
                </b-form-radio>
                <b-form-radio
                  class="mb-3"
                  value="2"
                  :disabled="sec2q30_31 !== '2'"
                  >ข. ใช้งานได้ 1 ชุด ใช้ไม่ได้ 1 ชุด
                </b-form-radio>
                <b-form-radio value="3" :disabled="sec2q30_31 !== '2'"
                  >ค. ใช้ไม่ได้ทั้ง 2 ชุด</b-form-radio
                >
              </b-form-radio-group>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.3.2)</span>
        ตู้ควบคุมเครื่องสูบน้ำดี
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 32)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q32"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/อุปกรณ์ภายในตู้ไม่ครบ)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.3.3)</span>
        หอถังสูง<span class="font-reddanger weight-500">
          (ถ้าระบบประปาใช้ถังอัดความดัน ไม่ต้องทำข้อนี้)</span
        >
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 33)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q33"
              stacked
              @change="selected_2_3_3"
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/รั่วซึม)
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-4">
        <span class="mr-2">2.3.3.1)</span>
        ป้าย หรืออุปกรณ์บอกปริมาตรน้ำในหอถังสูง
        <span class="font-reddanger weight-500">
          (ถ้าระบบประปาใช้ถังอัดความดัน ไม่ต้องทำข้อนี้)</span
        >
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio pl-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 34)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q34"
              stacked
              :disabled="
                !form.filter((f) => f.surveyQuestionId === 33)[0]
                  .surveyQuestionChoiceId
              "
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม
                (ชำรุด/อ่านค่าปริมาตรน้ำไม่ได้)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.3.4)</span>
        ถังอัดความดัน (Pressure Tank)
        <span class="font-reddanger weight-500">
          (ถ้าระบบประปาใช้หอถังสูง ไม่ต้องทำข้อนี้)</span
        >
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 35)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q35"
              stacked
              @change="selected_2_3_4"
            >
              <b-form-radio class="mb-3" value="1">ก. สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/ใช้งานไม่ได้)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-4">
        <span class="mr-2">2.3.4.1)</span>
        สวิทซ์แรงดัน เกจวัดแรงดัน และสวิทซ์ระบายแรงดัน
        <span class="font-reddanger weight-500">
          (ถ้าระบบประปาใช้หอถังสูง ไม่ต้องทำข้อนี้)</span
        >
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio pl-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 36)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q36"
              stacked
              :disabled="
                !form.filter((f) => f.surveyQuestionId === 35)[0]
                  .surveyQuestionChoiceId
              "
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/ใช้งานไม่ได้)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.3.5)</span>
        มาตรวัดน้ำหลัก หรือมิเตอร์วัดน้ำก่อนออกจากระบบประปา
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 37)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q37"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี สภาพทรุดโทรม (ชำรุด/ใช้งานไม่ได้)</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 2.3.6)</span>
        ท่อจ่ายน้ำและอุปกรณ์ท่อ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 38)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q38"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. สภาพดี</b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. ท่อหรืออุปกรณ์ท่อแตกรั่วซึมหรือชำรุดนานๆครั้ง</b-form-radio
              >
              <b-form-radio value="3"
                >ค. ท่อหรืออุปกรณ์ท่อแตกรั่วซึมหรือชำรุดบ่อย</b-form-radio
              >
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4" v-show="currentQuestion === 3">
      <div class="step-1 weight-700">
        3) ด้านการควบคุมการผลิตและบำรุงรักษาระบบประปา
      </div>
      <div class="step-2">
        3.1) คุณสมบัติของผู้ควบคุมการผลิตและบำรุงรักษาระบบประปา
      </div>
      <div class="step-3">
        <span class="mr-2"> 3.1.1)</span>
        การอบรมตามหลักสูตรของส่วนราชการ/สถาบันการศึกษาของรัฐ/เอกชนที่ได้มาตรฐาน
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 39)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q39"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. เคยได้รับการอบรมฯ/อยู่ระหว่างการฝึกอบรม</b-form-radio
              >
              <b-form-radio value="2">ข. ไม่เคยได้รับการอบรมฯ</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 3.1.2)</span>
        ประสบการณ์การควบคุมการผลิตและบำรุงรักษาระบบประปา
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 40)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q40"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. 3 ปี ขึ้นไป</b-form-radio>
              <b-form-radio class="mb-3" value="2">ข. 1 - 3 ปี</b-form-radio>
              <b-form-radio value="3">ค. น้อยกว่า 1 ปี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-2">3.2) การควบคุมการผลิตและบำรุงรักษาระบบประปา</div>
      <div class="step-3">
        <span class="mr-2"> 3.2.1)</span>
        การตรวจสอบสภาพน้ำดิบก่อนการเติมสารส้ม/ปูนขาว
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 41)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q41"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. มี ทุก 1-2 เดือน/ครั้ง
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี ทุก 3-5 เดือน/ครั้ง
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่มีการตรวจสอบ</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 3.2.2)</span>
        การเติมสารละลายสารส้มหรือสารอื่นที่ช่วยในการตกตะกอนของน้ำดิบ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 42)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q42"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. เติม เป็นประจำ
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. เติม เป็นบางครั้ง</b-form-radio
              >
              <b-form-radio value="3">ค. ไม่เติม </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 3.2.3)</span>
        การเติมสารละลายปูนขาวหรือสารอื่นที่ช่วยปรับค่า PH ของน้ำดิบ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 43)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q43"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. จำเป็นและเติมเป็นประจำ หรือไม่จำเป็นต้องเติม
                เพราะไม่เติมน้ำดิบก็ตกตะกอนได้ดี
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. จำเป็นและเติมเป็นบางครั้ง
              </b-form-radio>
              <b-form-radio class="mb-3" value="3"
                >ค. จำเป็น แต่ไม่ได้เติม
              </b-form-radio>
              <b-form-radio value="4">ง. ไม่มีการตรวจสอบ </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2">3.2.4)</span>
        การล้างทำความสะอาดระบบสร้างตะกอน ระบบรวมตะกอน ระบบตกตะกอน
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 44)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q44"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. 1-6 เดือน/ครั้ง
              </b-form-radio>
              <b-form-radio class="mb-3" value="2">ข. 1 ปี/ครั้ง </b-form-radio>
              <b-form-radio class="mb-3" value="3"
                >ค. มากกว่า 1 ปี/ครั้ง
              </b-form-radio>
              <b-form-radio value="4">ง. ไม่เคยล้างทำความสะอาด </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 3.2.5)</span>
        การล้างทำความสะอาดถังกรอง
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 45)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q45"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. 1-6 เดือน/ครั้ง
              </b-form-radio>
              <b-form-radio class="mb-3" value="2">ข. 1 ปี/ครั้ง </b-form-radio>
              <b-form-radio class="mb-3" value="3"
                >ค. มากกว่า 1 ปี/ครั้ง
              </b-form-radio>
              <b-form-radio value="4">ง. ไม่เคยล้างทำความสะอาด</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 3.2.6)</span>
        การล้างย้อนทรายกรอง (Back wash)
      </div>
      <div class="step-4">
        <span class="mr-2"> 3.2.6.1)</span>
        ก่อนการล้างย้อนทรายกรอง
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 46)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q46"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. สังเกตระดับน้ำในถังกรอง/หลอดวัดความฝืดหน้าทราย
              </b-form-radio>
              <b-form-radio value="2"
                >ข.
                ไม่เคยสังเกตระดับน้ำในถังกรอง/หลอดวัดความฝืดหน้าทราย</b-form-radio
              >
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-4">
        <span class="mr-2"> 3.2.6.2)</span>
        การล้างย้อนทรายกรอง
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 47)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q47"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ล้างเป็นประจำ อย่างน้อย 2 วัน/ครั้ง
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. ล้างนานๆ ครั้ง
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่เคยล้างย้อนทรายกรอง </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-3">
        <span class="mr-2"> 3.2.7)</span>
        การล้างทำความสะอาดถังน้ำใส
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 48)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q48"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. 1 ปี/ครั้ง </b-form-radio>
              <b-form-radio class="mb-3" value="2">ข. 2 ปี/ครั้ง </b-form-radio>
              <b-form-radio class="mb-3" value="3"
                >ค. มากกว่า 2 ปี/ครั้ง
              </b-form-radio>
              <b-form-radio value="4">ง. ไม่เคยล้างทำความสะอาด </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2">3.2.8)</span>
        การล้างทำความสะอาดหอถังสูง
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 49)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q49"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. 1 ปี/ครั้ง </b-form-radio>
              <b-form-radio class="mb-3" value="2">ข. 2 ปี/ครั้ง </b-form-radio>
              <b-form-radio class="mb-3" value="3"
                >ค. มากกว่า 2 ปี/ครั้ง
              </b-form-radio>
              <b-form-radio value="4">ง. ไม่เคยล้างทำความสะอาด </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2">3.2.9)</span>
        การควบคุมเครื่องสูบน้ำ
      </div>
      <div class="step-4">
        <span class="mr-2">3.2.9.1)</span>
        ตรวจสอบสภาพการทำงานของเครื่องสูบน้ำ (น้ำรั่วซึม/ฟังเสียง/สังเกตกลิ่นไหม้
        ฯลฯ)
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 50)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q50"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ตรวจสอบ เป็นประจำ
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. ตรวจสอบ เป็นบางครั้ง
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่เคยตรวจสอบ </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-4">
        <span class="mr-2"> 3.2.9.2)</span>
        ตรวจสอบสภาพการทำงานของตู้ควบคุมของเครื่องสูบน้ำ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 51)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q51"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ตรวจสอบ เป็นประจำ
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. ตรวจสอบ เป็นบางครั้ง
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่เคยตรวจสอบ </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-4">
        <span class="mr-2"> 3.2.9.3)</span>
        บันทึกชั่วโมงการทำงานของเครื่องสูบน้ำ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 52)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q52"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. บันทึก เป็นประจำ
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. บันทึก เป็นบางครั้ง
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่เคยบันทึก </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 3.2.10)</span>
        การเติมสารละลายคลอรีนเพื่อฆ่าเชื้อโรคในน้ำประปา
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 53)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q53"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. เติม เป็นประจำ
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. เติม เป็นบางครั้ง
              </b-form-radio>
              <b-form-radio value="3"
                >ค. ไม่เติม/ไม่มีเครื่องจ่ายสารละลายคลอรีน
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 3.2.11)</span>
        การใช้เครื่องวิเคราะห์คลอรีนหลงเหลือ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 54)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q54"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ใช้ เป็นประจำ
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. ใช้ เป็นบางครั้ง
              </b-form-radio>
              <b-form-radio value="3"
                >ค. ไม่ใช้/ไม่มีเครื่องวิเคราะห์คลอรีนหลงเหลือ
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 3.2.12)</span>
        การบันทึกข้อมูลการเติมสารเคมี
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 55)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q55"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. มีการบันทึก เป็นประจำ
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มีการบันทึก เป็นบางครั้ง
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่มีการบันทึก </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-2">3.3) การซ่อมแซม/เปลี่ยน อุปกรณ์และระบบควบคุม</div>
      <div class="step-3">
        <span class="mr-2"> 3.3.1)</span>
        หากท่อเมนจ่ายน้ำมีการแตกรั่ว
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 56)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q56"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ใช้เวลาซ่อมภายใน 1 วัน หลังจากตรวจพบ
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. ใช้เวลาซ่อมภายใน 2 วัน หลังจากตรวจพบ
              </b-form-radio>
              <b-form-radio class="mb-3" value="3"
                >ค. ใช้เวลาซ่อมมากกว่า 3-5 วัน หลังจากตรวจพบ
              </b-form-radio>
              <b-form-radio value="4"
                >ง. ใช้เวลาซ่อมมากกว่า 5 วัน หลังจากตรวจพบ
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 3.3.2)</span>
        ในรอบ 1 ปี ต้องหยุดจ่ายน้ำประปา (เนื่องจากการซ่อมแซมระบบ เช่น ระบบไฟฟ้า
        ระบบผลิต ระบบจ่ายท่อจ่ายน้ำ เป็นต้น)
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 57)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q57"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ไม่เคยหยุดจ่าย หรือหยุดจ่าย 1 ครั้ง
              </b-form-radio>
              <b-form-radio class="mb-3" value="2">ข. 2 ครั้ง </b-form-radio>
              <b-form-radio class="mb-3" value="3">ค. 3 ครั้ง </b-form-radio>
              <b-form-radio value="4">ง. มากกว่า 3 ครั้ง </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-2">
        3.4) การควบคุมปริมาณน้ำสูญเสียให้อยู่ในเกณฑ์ที่กำหนด
      </div>
      <div class="step-3">
        <span class="mr-2"> 3.4.1)</span>
        ความแตกต่างระหว่างมาตรวัดน้ำหลัก (มิเตอร์วัดน้ำก่อนออกจากระบบประปา)
        กับผลรวมของมาตรวัดน้ำย่อยจากบ้านผู้ใช้น้ำ เช่น จดมาตรวัดน้ำหลักได้ 200
        หน่วย ผลรวมของมาตรวัดน้ำย่อยจากบ้านผู้ใช้น้ำเท่ากับ 150 หน่วย จะได้
        (200-150)/200 = 0.25 ดังนั้นปริมาณน้ำสูญเสีย = 0.25 x 100 = 25%
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 58)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q58"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ไม่เกิน 25 %
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. 26 % - 30 %
              </b-form-radio>
              <b-form-radio class="mb-3" value="3"
                >ค. มากกว่า 30 %
              </b-form-radio>
              <b-form-radio value="4">ง. ไม่มีการบันทึกข้อมูล </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-2">
        3.5) ค่าตอบแทนของผู้ควบคุมการผลิตและบำรุงรักษาระบบประปา
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 59)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q59"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ได้รับค่าจ้างเป็นรายเดือน</b-form-radio
              >
              <b-form-radio class="mb-3" value="2"
                >ข. ได้รับค่าตอบแทนเป็นอย่างอื่น
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่ได้รับค่าตอบแทน</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4" v-show="currentQuestion === 4">
      <div class="step-1 weight-700">
        4) ด้านปริมาณน้ำ แรงดันน้ำ และคุณภาพน้ำประปา
      </div>
      <div class="step-2">4.1) ปริมาณน้ำประปา</div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 60)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q60"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. เพียงพอกับความต้องการของผู้ใช้น้ำ</b-form-radio
              >
              <b-form-radio value="2"
                >ข. ไม่เพียงพอ จ่ายได้เป็นบางเวลา/บางพื้นที่
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-2">4.2) แรงดันน้ำ (พิจารณา เฉพาะการใช้งานชั้นล่าง)</div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 61)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q61"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก.
                น้ำไหลแรงครอบคลุมพื้นที่ให้บริการจ่ายน้ำตลอดเวลา</b-form-radio
              >
              <b-form-radio class="mb-3" value="2"
                >ข. น้ำไหลแรงเป็นบางพื้นที่ และบางเวลา
              </b-form-radio>
              <b-form-radio value="3"
                >ค. น้ำไหลอ่อนทุกพื้นที่ตลอดเวลา
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-2">4.3) คุณภาพน้ำประปาเบื้องต้น (ตอบทุกข้อ)</div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio mb-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 62)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q62"
              inline
            >
              <label for="" class="mr-5">4.3.1)</label>
              <b-form-radio style="width: 140px" value="1">ก. ใส</b-form-radio>
              <b-form-radio style="width: 140px" value="2"
                >ข. ขุ่น</b-form-radio
              >
            </b-form-radio-group>
            <b-form-radio-group
              class="my-radio mb-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 63)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q63"
              inline
            >
              <label for="" class="mr-5">4.3.2)</label>
              <b-form-radio style="width: 140px" value="1"
                >ก. ไม่มีกลิ่น</b-form-radio
              >
              <b-form-radio style="width: 140px" value="2"
                >ข. มีกลิ่น</b-form-radio
              >
            </b-form-radio-group>
            <b-form-radio-group
              class="my-radio mb-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 64)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q64"
              inline
            >
              <label for="" class="mr-5">4.3.3)</label>
              <b-form-radio style="width: 140px" value="1">ก. จืด</b-form-radio>
              <b-form-radio style="width: 140px" value="2"
                >ข. กร่อย , เค็ม
              </b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group
              class="my-radio mb-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 65)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q65"
              inline
            >
              <label for="" class="mr-5">4.3.4)</label>
              <b-form-radio style="width: 140px" value="1"
                >ก. ไม่เปรี้ยว</b-form-radio
              >
              <b-form-radio style="width: 140px" value="2"
                >ข. เปรี้ยว</b-form-radio
              >
            </b-form-radio-group>
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 66)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q66"
              inline
            >
              <label for="" class="mr-5">4.3.5)</label>
              <b-form-radio style="width: 140px" value="1"
                >ก. ไม่กระด้าง</b-form-radio
              >
              <b-form-radio style="width: 140px" value="2">
                ข. กระด้าง</b-form-radio
              >
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-2">
        4.4) การส่งตัวอย่างน้ำประปาที่ผลิตได้ เพื่อวิเคราะห์คุณภาพน้ำ
        ในห้องปฏิบัติการ (ในรอบ 3 ปี ที่ผ่านมา)
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 67)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q67"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ส่งวิเคราะห์ฯ และผ่านเกณฑ์คุณภาพน้ำประปาดื่มได้ พ.ศ. 2553
                ของกรมอนามัย</b-form-radio
              >
              <b-form-radio class="mb-3" value="2"
                >ข. ส่งวิเคราะห์ฯ แต่ไม่ผ่านเกณฑ์คุณภาพน้ำประปาดื่มได้ พ.ศ. 2553
                ของกรมอนามัย
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่เคยส่งวิเคราะห์ฯ </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-2">
        4.5) ปริมาณคลอรีนหลงเหลือที่ปลายท่อจ่ายน้ำ (ปลายท่อจ่ายน้ำที่ไกลที่สุด)
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 68)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q68"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. 0.2 - 0.5 มก./ลิตร
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มากกว่า 0.5 มก./ลิตร
              </b-form-radio>
              <b-form-radio class="mb-3" value="3"
                >ค. น้อยกว่า 0.2 มก./ลิตร
              </b-form-radio>
              <b-form-radio value="4"
                >ง. ไม่มีการตรวจวัดปริมาณคลอรีนหลงเหลือ
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-2">4.6) ผลการวิเคราะห์คุณภาพน้ำ</div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12 mb-3">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 69)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q69"
              stacked
            >
              <b-form-radio value="1" class="mb-3">ก. ผ่าน</b-form-radio>
              <b-form-radio value="2">ข. ไม่ผ่าน </b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="col-sm-12 d-flex align-items-center">
            <div class="mr-2">แนบไฟล์ผลการวิเคราะห์คุณภาพน้ำ :</div>
            <button class="btn btn-submit" @click="chooseFiles()">
              <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />
              อัพโหลดเอกสาร
            </button>
            <div class="ml-2">
              {{ tempFile.fileName }}
            </div>
            <input
              ref="fileUpload"
              type="file"
              @change="(val) => getFiles(val)"
              hidden
            />
            <!-- <span class="ml-3">*รองรับไฟล์เอกสารประเภท .pdf เท่านั้น</span> -->
          </div>
          <div class="col-12" v-show="fileUploaded">
            <span
              class="cursor-pointer font-blueinfo"
              style="text-decoration: underline"
              @click="downloadFile(fileUploaded)"
              >(ดาวน์โหลดไฟล์แนบ)</span
            >
            <!-- <a
              class="font-blueinfo cursor-pointer"
              style="text-decoration: underline"
              v-text="'(ดาวน์โหลดไฟล์แนบ)'"
              target="_blank"
              :href="fileUploaded"
              download
            /> -->
          </div>
        </div>
      </div>
    </div>

    <div class="blue-box mb-4" v-show="currentQuestion === 5">
      <div class="step-1 weight-700">5) ด้านการบริหารกิจการระบบประปา</div>
      <div class="step-2">5.1) การบริหารจัดการระบบประปาหมู่บ้าน</div>
      <div class="step-3">
        <span class="mr-2"> 5.1.1)</span>
        ผู้บริหารกิจการประปาหมู่บ้านเคยเข้ารับการอบรมการบริหารกิจการประปาหมู่บ้านตามหลักสูตรของส่วนราชการ/สถาบันการศึกษาของรัฐ/เอกชนที่ได้มาตรฐาน
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 70)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q70"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. เคยได้รับการอบรมฯ/อยู่ระหว่างการฝึกอบรม</b-form-radio
              >
              <b-form-radio value="2">ข. ไม่เคยได้รับการอบรมฯ </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.1.2)</span>
        กฎระเบียบในการบริหารกิจการประปาหมู่บ้านมีหลักฐานเป็นลายลักษณ์อักษร
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 71)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q71"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี</b-form-radio>
              <b-form-radio value="2">ข. ไม่มี </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.1.3)</span>
        การแจ้งข่าวสารการบริหารกิจการระบบประปาหมู่บ้าน แก่ผู้ใช้น้ำ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 72)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q72"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. มี ประจำ 1-3 เดือน
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี ประจำ 4-6 เดือน
              </b-form-radio>
              <b-form-radio class="mb-3" value="3">ค. มี ประจำปี </b-form-radio>
              <b-form-radio value="4">ง. ไม่มี </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.1.4)</span>
        กำหนดการประชุมของคณะกรรมการฯ หรือ อปท.
        เกี่ยวกับการบริหารกิจการระบบประปาหมู่บ้าน
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 73)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q73"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. มี กำหนดวาระที่แน่นอน
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มี แต่ไม่มีกำหนดวาระที่แน่นอน
              </b-form-radio>
              <b-form-radio value="3">ค. ไม่มีการประชุม </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-2">5.2) การเงินและบัญชี</div>
      <div class="step-3">
        <span class="mr-2"> 5.2.1)</span>
        การวิเคราะห์ต้นทุนค่าน้ำประปา
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 74)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q74"
              stacked
            >
              <b-form-radio inline class="mb-3" value="1">ก. มี </b-form-radio>
              <b-form-radio value="2">ข. ไม่มี </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.2.2)</span>
        ในการกำหนดค่าน้ำประปา มีการรับฟังความคิดเห็นจากผู้ใช้น้ำ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 75)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q75"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี </b-form-radio>
              <b-form-radio value="2">ข. ไม่มี </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.2.3)</span>
        ประปาหมู่บ้านมีกำไรสุทธิเฉลี่ยต่อเดือนเท่าไร ในรอบ 1 ปี
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 76)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q76"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. กำไรมากกว่า 2,000 บาท ขึ้นไป</b-form-radio
              >
              <b-form-radio class="mb-3" value="2"
                >ข. กำไรตั้งแต่ 1,000 - 2,000 บาท
              </b-form-radio>
              <b-form-radio class="mb-3" value="3"
                >ค. กำไรต่ำกว่า 1,000 บาท
              </b-form-radio>
              <b-form-radio value="4">ง. ไม่มีกำไรหรือขาดทุน </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.2.4)</span>
        การเก็บเงินกองทุนโดยการฝากธนาคารหรือสถาบันการเงิน
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 77)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q77"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี</b-form-radio>
              <b-form-radio value="2">ข. ไม่มี </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.2.5)</span>
        การจัดทำระบบบัญชีรายรับ - รายจ่าย
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio mb-3"
              v-model="
                form.filter((f) => f.surveyQuestionId === 78)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q78"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. มีการจัดทำระบบบัญชีรายรับ - รายจ่าย และ
                มีการประชาสัมพันธ์</b-form-radio
              >
              <b-form-radio class="mb-3" value="2"
                >ข. มีการจัดทำระบบบัญชีรายรับ - รายจ่าย แต่ไม่มีการประชาสัมพันธ์
              </b-form-radio>
              <b-form-radio value="3"
                >ค. ไม่มี การจัดทำระบบบัญชีรายรับ - รายจ่าย
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-2">5.3) สมาชิกผู้ใช้น้ำ</div>
      <div class="step-3">
        <span class="mr-2"> 5.3.1)</span>
        สมาชิกผู้ใช้น้ำค้างชำระเกินกว่า 1 เดือน
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 79)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q79"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. ไม่มี</b-form-radio>
              <b-form-radio value="2">ข. มี </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.3.2)</span>
        ในหมู่บ้านมีผู้ใช้น้ำฟรีหรือไม่
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 80)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q80"
              stacked
            >
              <b-form-radio class="mb-3" value="1"
                >ก. ไม่มีผู้ใช้น้ำฟรี
              </b-form-radio>
              <b-form-radio class="mb-3" value="2"
                >ข. มีผู้ใช้น้ำฟรีตามหลักเกณฑ์</b-form-radio
              >
              <b-form-radio value="3"
                >ค. มีผู้ใช้น้ำฟรีโดยปราศจากหลักเกณฑ์
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-2">5.4) แบบแปลน/คู่มือ</div>
      <div class="step-3">
        <span class="mr-2"> 5.4.1)</span>
        แบบผังแนวท่อส่งน้ำดิบ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 81)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q81"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี</b-form-radio>
              <b-form-radio value="2">ข. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.4.2)</span>
        แบบผังระบบผลิตประปา/การประสานท่อระหว่างระบบฯ
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 82)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q82"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี</b-form-radio>
              <b-form-radio value="2">ข. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.4.3)</span>
        แบบผังแนวท่อเมนจ่ายน้ำประปา
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 83)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q83"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี</b-form-radio>
              <b-form-radio value="2">ข. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="step-3">
        <span class="mr-2"> 5.4.4)</span>
        คู่มือการควบคุมการผลิตน้ำประปา/การบริหารกิจการประปา
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 84)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q84"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี</b-form-radio>
              <b-form-radio value="2">ข. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>

      <div class="step-2">5.5) การบันทึกประวัติการซ่อมแซมระบบประปา</div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="
                form.filter((f) => f.surveyQuestionId === 85)[0]
                  .surveyQuestionChoiceId
              "
              name="sec2q85"
              stacked
            >
              <b-form-radio class="mb-3" value="1">ก. มี</b-form-radio>
              <b-form-radio value="2">ข. ไม่มี</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-5">
      <div class="col-4 text-center">
        <button type="button" class="btn btn-back" @click="prevQuestion">
          ย้อนกลับ
        </button>
      </div>
      <div class="col-4 text-center">
        <button type="button" class="btn btn-submit" @click="nextQuestion">
          ถัดไป
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "surfacewater-section2",
  props: {
    questions: {
      type: Array,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => null,
    },
    toReload: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      apiURL: null,
      currentQuestion: 1,
      choiceValueTempQ1_1: null,
      choiceValueTempQ1_2: null,
      choiceValueTempQ1_3: null,
      choiceValueTempQ1_4: null,
      choiceValueTempQ1_5: null,

      choiceValueTempQ2_1_1: null,
      choiceValueTempQ2_1_2: null,
      choiceValueTempQ2_2_1: null,
      choiceValueTempQ2_2_2: null,

      fileForUpload: { isUpload: false },
      tempFile: { fileName: null, size: null },

      sec2q8_9: null,
      sec2q30_31: null,
      form: [],
      defaultQuestions: [
        { surveyQuestionId: 1, surveyQuestionChoiceId: null },
        { surveyQuestionId: 2, surveyQuestionChoiceId: null },
        { surveyQuestionId: 3, surveyQuestionChoiceId: null },
        { surveyQuestionId: 4, surveyQuestionChoiceId: null },
        { surveyQuestionId: 5, surveyQuestionChoiceId: null },
        { surveyQuestionId: 6, surveyQuestionChoiceId: null },
        { surveyQuestionId: 7, surveyQuestionChoiceId: null },
        //ด้าน 2
        { surveyQuestionId: 8, surveyQuestionChoiceId: null },
        { surveyQuestionId: 9, surveyQuestionChoiceId: null },
        { surveyQuestionId: 10, surveyQuestionChoiceId: null },
        { surveyQuestionId: 11, surveyQuestionChoiceId: null },
        { surveyQuestionId: 12, surveyQuestionChoiceId: null },
        { surveyQuestionId: 13, surveyQuestionChoiceId: null },
        { surveyQuestionId: 14, surveyQuestionChoiceId: null },
        { surveyQuestionId: 15, surveyQuestionChoiceId: null },
        { surveyQuestionId: 16, surveyQuestionChoiceId: null },
        { surveyQuestionId: 17, surveyQuestionChoiceId: null },
        { surveyQuestionId: 18, surveyQuestionChoiceId: null },
        { surveyQuestionId: 19, surveyQuestionChoiceId: null },
        { surveyQuestionId: 20, surveyQuestionChoiceId: null },
        { surveyQuestionId: 21, surveyQuestionChoiceId: null },
        { surveyQuestionId: 22, surveyQuestionChoiceId: null },
        { surveyQuestionId: 23, surveyQuestionChoiceId: null },
        { surveyQuestionId: 24, surveyQuestionChoiceId: null },
        { surveyQuestionId: 25, surveyQuestionChoiceId: null },
        { surveyQuestionId: 26, surveyQuestionChoiceId: null },
        { surveyQuestionId: 27, surveyQuestionChoiceId: null },
        { surveyQuestionId: 28, surveyQuestionChoiceId: null },
        { surveyQuestionId: 29, surveyQuestionChoiceId: null },
        { surveyQuestionId: 30, surveyQuestionChoiceId: null },
        { surveyQuestionId: 31, surveyQuestionChoiceId: null },
        { surveyQuestionId: 32, surveyQuestionChoiceId: null },
        { surveyQuestionId: 33, surveyQuestionChoiceId: null },
        { surveyQuestionId: 34, surveyQuestionChoiceId: null },
        { surveyQuestionId: 35, surveyQuestionChoiceId: null },
        { surveyQuestionId: 36, surveyQuestionChoiceId: null },
        { surveyQuestionId: 37, surveyQuestionChoiceId: null },
        { surveyQuestionId: 38, surveyQuestionChoiceId: null },
        //ด้าน 3
        { surveyQuestionId: 39, surveyQuestionChoiceId: null },
        { surveyQuestionId: 40, surveyQuestionChoiceId: null },
        { surveyQuestionId: 41, surveyQuestionChoiceId: null },
        { surveyQuestionId: 42, surveyQuestionChoiceId: null },
        { surveyQuestionId: 43, surveyQuestionChoiceId: null },
        { surveyQuestionId: 44, surveyQuestionChoiceId: null },
        { surveyQuestionId: 45, surveyQuestionChoiceId: null },
        { surveyQuestionId: 46, surveyQuestionChoiceId: null },
        { surveyQuestionId: 47, surveyQuestionChoiceId: null },
        { surveyQuestionId: 48, surveyQuestionChoiceId: null },
        { surveyQuestionId: 49, surveyQuestionChoiceId: null },
        { surveyQuestionId: 50, surveyQuestionChoiceId: null },
        { surveyQuestionId: 51, surveyQuestionChoiceId: null },
        { surveyQuestionId: 52, surveyQuestionChoiceId: null },
        { surveyQuestionId: 53, surveyQuestionChoiceId: null },
        { surveyQuestionId: 54, surveyQuestionChoiceId: null },
        { surveyQuestionId: 55, surveyQuestionChoiceId: null },
        { surveyQuestionId: 56, surveyQuestionChoiceId: null },
        { surveyQuestionId: 57, surveyQuestionChoiceId: null },
        { surveyQuestionId: 58, surveyQuestionChoiceId: null },
        { surveyQuestionId: 59, surveyQuestionChoiceId: null },
        //ด้าน 4
        { surveyQuestionId: 60, surveyQuestionChoiceId: null },
        { surveyQuestionId: 61, surveyQuestionChoiceId: null },
        { surveyQuestionId: 62, surveyQuestionChoiceId: null },
        { surveyQuestionId: 63, surveyQuestionChoiceId: null },
        { surveyQuestionId: 64, surveyQuestionChoiceId: null },
        { surveyQuestionId: 65, surveyQuestionChoiceId: null },
        { surveyQuestionId: 66, surveyQuestionChoiceId: null },
        { surveyQuestionId: 67, surveyQuestionChoiceId: null },
        { surveyQuestionId: 68, surveyQuestionChoiceId: null },
        { surveyQuestionId: 69, surveyQuestionChoiceId: null },
        //ด้าน 5
        { surveyQuestionId: 70, surveyQuestionChoiceId: null },
        { surveyQuestionId: 71, surveyQuestionChoiceId: null },
        { surveyQuestionId: 72, surveyQuestionChoiceId: null },
        { surveyQuestionId: 73, surveyQuestionChoiceId: null },
        { surveyQuestionId: 74, surveyQuestionChoiceId: null },
        { surveyQuestionId: 75, surveyQuestionChoiceId: null },
        { surveyQuestionId: 76, surveyQuestionChoiceId: null },
        { surveyQuestionId: 77, surveyQuestionChoiceId: null },
        { surveyQuestionId: 78, surveyQuestionChoiceId: null },
        { surveyQuestionId: 79, surveyQuestionChoiceId: null },
        { surveyQuestionId: 80, surveyQuestionChoiceId: null },
        { surveyQuestionId: 81, surveyQuestionChoiceId: null },
        { surveyQuestionId: 82, surveyQuestionChoiceId: null },
        { surveyQuestionId: 83, surveyQuestionChoiceId: null },
        { surveyQuestionId: 84, surveyQuestionChoiceId: null },
        { surveyQuestionId: 85, surveyQuestionChoiceId: null },
      ],
    };
  },
  async created() {
    window._surface2 = this;
    this.apiURL = process.env.VUE_APP_BASE_API_DOWNLOAD;

    this.form = [...this.defaultQuestions];
  },
  methods: {
    bindSurveyDetailAdd() {
      this.form = [...this.defaultQuestions];
    },
    bindSurveyDetail() {
      const _temp = [...this.defaultQuestions];

      this.form = _temp.map((m) => {
        const questionId = m.surveyQuestionId;

        const result =
          this.questions.filter((ff) => {
            return ff.surveyQuestionId == questionId;
          })[0] || undefined;

        //check ข้อ1, 2
        if (result?.surveyQuestionId == 1 && !!result?.surveyQuestionChoiceId) {
          const _choiceId = parseInt(result.surveyQuestionChoiceId);

          switch (_choiceId) {
            case 2:
              this.choiceValueTempQ1_2 = result.surveyQuestionChoiceValue1;
              break;
            case 3:
              this.choiceValueTempQ1_3 = result.surveyQuestionChoiceValue1;
              break;
            case 4:
              this.choiceValueTempQ1_4 = result.surveyQuestionChoiceValue1;
              break;
            case 5:
              this.choiceValueTempQ1_5 = result.surveyQuestionChoiceValue1;
              break;
          }
        } else if (
          result?.surveyQuestionId == 2 &&
          !!result?.surveyQuestionChoiceId
        ) {
          const _choiceId = parseInt(result.surveyQuestionChoiceId);
          switch (_choiceId) {
            case 1:
              this.choiceValueTempQ2_1_1 = result.surveyQuestionChoiceValue1;
              this.choiceValueTempQ2_1_2 = result.surveyQuestionChoiceValue2;
              break;
            case 2:
              this.choiceValueTempQ2_2_1 = result.surveyQuestionChoiceValue1;
              this.choiceValueTempQ2_2_2 = result.surveyQuestionChoiceValue2;
              break;
          }
        }

        if (result) {
          m = { ...result };
        }

        return { ...m };
      });

      //disbled/visible
      //get choice id 8,9
      const _q8 =
        this.form.filter((f) => {
          return f.surveyQuestionId === 8;
        })[0] || undefined;

      const _q9 =
        this.form.filter((f) => {
          return f.surveyQuestionId === 9;
        })[0] || undefined;

      if (_q8 && !!_q8?.surveyQuestionChoiceId) {
        this.sec2q8_9 = "1";
        this.clearAnswer2_1_1(1);
      }
      if (_q9 && !!_q9?.surveyQuestionChoiceId) {
        this.sec2q8_9 = "2";
        this.clearAnswer2_1_1(2);
      }

      //sec2q30_31
      const _q30 =
        this.form.filter((f) => {
          return f.surveyQuestionId === 30;
        })[0] || undefined;

      const _q31 =
        this.form.filter((f) => {
          return f.surveyQuestionId === 31;
        })[0] || undefined;

      if (_q30 && !!_q30?.surveyQuestionChoiceId) {
        this.sec2q30_31 = "1";
        this.clearAnswer2_3_1(1);
      }
      if (_q31 && !!_q31?.surveyQuestionChoiceId) {
        this.sec2q30_31 = "2";
        this.clearAnswer2_3_1(2);
      }
    },
    prevQuestion() {
      if (this.currentQuestion > 1) {
        this.currentQuestion -= 1;
        window.scrollTo(0, 0);
      } else {
        this.prevCurSection();
      }
    },
    validateQ1() {
      for (let i = 1; i <= 7; i++) {
        if (this.form[i - 1].surveyQuestionChoiceId === null) return false;
      }

      return true;
    },
    validateQ2() {
      if (this.sec2q8_9 === null) return false;
      else if (this.sec2q8_9 === "1") {
        if (this.form[7].surveyQuestionChoiceId === null) return false;
      } else if (this.sec2q8_9 === "2") {
        if (this.form[8].surveyQuestionChoiceId === null) return false;
      }
      if (this.sec2q30_31 === null) return false;
      else if (this.sec2q30_31 === "1") {
        if (this.form[29].surveyQuestionChoiceId === null) return false;
      } else if (this.sec2q30_31 === "2") {
        if (this.form[30].surveyQuestionChoiceId === null) return false;
      }
      for (let i = 8; i <= 38; i++) {
        if (
          i !== 8 &&
          i !== 9 &&
          i !== 30 &&
          i !== 31 &&
          i !== 33 &&
          i !== 34 &&
          i !== 35 &&
          i !== 36
        ) {
          if (this.form[i - 1].surveyQuestionChoiceId === null) return false;
        }
      }

      if (
        this.form.filter((f) => f.surveyQuestionId === 33)[0]
          .surveyQuestionChoiceId == null &&
        this.form.filter((f) => f.surveyQuestionId === 35)[0]
          .surveyQuestionChoiceId == null
      ) {
        return false;
      } else if (
        this.form.filter((f) => f.surveyQuestionId === 33)[0]
          .surveyQuestionChoiceId != null &&
        this.form.filter((f) => f.surveyQuestionId === 34)[0]
          .surveyQuestionChoiceId == null
      ) {
        return false;
      } else if (
        this.form.filter((f) => f.surveyQuestionId === 35)[0]
          .surveyQuestionChoiceId != null &&
        this.form.filter((f) => f.surveyQuestionId === 36)[0]
          .surveyQuestionChoiceId == null
      ) {
        return false;
      }

      return true;
    },
    validateQ3() {
      for (let i = 39; i <= 59; i++) {
        if (this.form[i - 1].surveyQuestionChoiceId === null) return false;
      }

      return true;
    },
    validateQ4() {
      for (let i = 60; i <= 69; i++) {
        if (this.form[i - 1].surveyQuestionChoiceId === null) return false;
      }

      return true;
    },
    validateQ5() {
      for (let i = 70; i <= 85; i++) {
        if (this.form[i - 1].surveyQuestionChoiceId === null) return false;
      }

      return true;
    },
    nextQuestion() {
      if (this.currentQuestion < 5) {
        let validate = false;
        if (this.currentQuestion === 1) {
          validate = this.validateQ1();
        } else if (this.currentQuestion === 2) {
          validate = this.validateQ2();
        } else if (this.currentQuestion === 3) {
          validate = this.validateQ3();
        } else if (this.currentQuestion === 4) {
          validate = this.validateQ4();
        }

        if (validate) {
          this.currentQuestion += 1;
          window.scrollTo(0, 0);
        } else {
          this.alertAssessmentFormValidate();
        }
      } else {
        if (this.validateQ5()) {
          this.nextCurSection();
        } else {
          this.alertAssessmentFormValidate();
        }
      }
    },
    prevCurSection() {
      this.$emit("updateCurSection", 1);
      window.scrollTo(0, 0);
    },
    nextCurSection() {
      this.$emit("updateCurSection", 3);
      this.$emit("updateQuestions", this.returnQuestions(), this.fileForUpload);
      window.scrollTo(0, 0);
    },
    chooseFiles() {
      this.$refs["fileUpload"].click();
    },
    getFiles(val) {
      const objFile = val.target.files;

      const getFileFirst = objFile[0];

      if (getFileFirst) {
        this.fileForUpload["isUpload"] = true;
        this.fileForUpload["file"] = getFileFirst;
        this.fileForUpload["surveyQuestionId"] = 69;

        this.tempFile["fileName"] = getFileFirst.name;
        this.tempFile["size"] = this.$utils.convert.formatBytes(
          getFileFirst.size
        );
      } else {
        this.fileForUpload = { isUpload: false };

        this.tempFile["fileName"] = null;
        this.tempFile["size"] = null;
      }
    },
    checkFile(file) {
      return true;
    },
    clearAnswer2_1_1(q) {
      if (q === 1) {
        this.form[8].surveyQuestionChoiceId = null;
      } else if (q === 2) {
        this.form[7].surveyQuestionChoiceId = null;
      }
    },
    clearAnswer2_3_1(q) {
      if (q === 1) {
        this.form[30].surveyQuestionChoiceId = null;
      } else if (q === 2) {
        this.form[29].surveyQuestionChoiceId = null;
      }
    },
    clearInput1_1(select) {
      if (select !== null || select !== "") {
        const _choiceId = parseInt(select);
        if (_choiceId !== 2) {
          this.choiceValueTempQ1_2 = null;
        }
        if (_choiceId !== 3) {
          this.choiceValueTempQ1_3 = null;
        }
        if (_choiceId !== 4) {
          this.choiceValueTempQ1_4 = null;
        }
        if (_choiceId !== 5) {
          this.choiceValueTempQ1_5 = null;
        }
      }
    },
    clearInput1_2(select) {
      if (select !== null || select !== "") {
        const _choiceId = parseInt(select);
        if (_choiceId !== 1) {
          this.choiceValueTempQ2_1_1 = null;
          this.choiceValueTempQ2_1_2 = null;
        }
        if (_choiceId !== 2) {
          this.choiceValueTempQ2_2_1 = null;
          this.choiceValueTempQ2_2_2 = null;
        }
      }
    },
    returnQuestions() {
      const result = this.form
        .filter((f) => {
          return (
            f.surveyQuestionChoiceId !== null ||
            (f.qualitySurveyId !== null && f.qualitySurveyId !== undefined)
          );
        })
        .map((m) => {
          if (m.surveyQuestionId === 1 && !!m.surveyQuestionChoiceId) {
            const _choiceId = parseInt(m.surveyQuestionChoiceId);
            switch (_choiceId) {
              case 2:
                m["surveyQuestionChoiceValue1"] = this.choiceValueTempQ1_2;
                break;
              case 3:
                m["surveyQuestionChoiceValue1"] = this.choiceValueTempQ1_3;
                break;
              case 4:
                m["surveyQuestionChoiceValue1"] = this.choiceValueTempQ1_4;
                break;
              case 5:
                m["surveyQuestionChoiceValue1"] = this.choiceValueTempQ1_5;
                break;
            }
          } else if (m.surveyQuestionId === 2 && !!m.surveyQuestionChoiceId) {
            const _choiceId = parseInt(m.surveyQuestionChoiceId);
            switch (_choiceId) {
              case 1:
                m["surveyQuestionChoiceValue1"] = this.choiceValueTempQ2_1_1;
                m["surveyQuestionChoiceValue2"] = this.choiceValueTempQ2_1_2;
                break;
              case 2:
                m["surveyQuestionChoiceValue1"] = this.choiceValueTempQ2_2_1;
                m["surveyQuestionChoiceValue2"] = this.choiceValueTempQ2_2_2;
                break;
            }
          }

          return m;
        });

      return result;
    },
    downloadFile(file) {
      try {
        window.open(file, "_blank");
      } catch (e) {
        this.alertDownloadFail();
      }
    },
    selected_2_3_3(value) {
      if (value != null) {
        this.form = this.form.map((m) => {
          let tempp = m;
          if (m.surveyQuestionId === 35 || m.surveyQuestionId === 36) {
            tempp = {
              ...m,
              surveyQuestionChoiceId: null,
            };
          }
          return {
            ...tempp,
          };
        });
      }
    },
    selected_2_3_4(value) {
      if (value != null) {
        this.form = this.form.map((m) => {
          let tempp = m;
          if (m.surveyQuestionId === 33 || m.surveyQuestionId === 34) {
            tempp = {
              ...m,
              surveyQuestionChoiceId: null,
            };
          }
          return {
            ...tempp,
          };
        });
      }
    },
  },
  watch: {
    toReload(val, oldVal) {
      if (val) {
        //check mode
        if (this.mode === "edit") {
          //get form detail
          this.bindSurveyDetail();
        } else {
          this.bindSurveyDetailAdd();
        }
      }
    },
  },
  computed: {
    fileUploaded() {
      const _file =
        this.form.filter((f) => f.surveyQuestionId === 69)[0]
          .surveyQuestionChoiceValue1 || undefined;

      if (_file && this.apiURL) {
        return `${this.apiURL}${_file}`;
      }

      return undefined;
    },
  },
};
</script>

<style lang="scss">
.surfacewater-section2 {
  .sec2-q1 {
    .my-radio {
      min-height: 38px;
    }

    label {
      width: 250px;
    }

    span {
      // width: 200px;
      margin-right: 10px;
    }

    input[type="text"] {
      max-width: 345px;
    }
  }

  .sec2-q2 {
    .my-radio {
      min-height: 38px;
    }

    label {
      width: 350px;
    }

    .input-width-1 {
      width: 300px;
    }
    .input-width-2 {
      width: 150px;
    }
  }
}
</style>
