<template>
  <div class="assessment-form">
    <!-- navigation -->
    <app-title-content
      class="mb-4"
      :items="breadcrumbs"
      :root="rootBreadcrumb"
      name="SurfaceForm"
    ></app-title-content>

    <!-- <div class="d-flex justify-content-between mb-4">
      <button type="submit" class="btn btn-transparent px-0 text-left">
        <iconify icon="akar-icons:arrow-left" class="mb-1 mx-2" /> ย้อนกลับ
      </button>
      <button
        type="submit"
        class="btn btn-outline-submit"
        @click="goToAssessmentList()"
      >
        <iconify icon="fa-solid:home" class="fontsize-h4 mr-2" />
        กลับไปยังหน้ารายการ
      </button>
    </div> -->
    <div class="font-headblue fontsize-h3 weight-500 mb-4">
      <iconify icon="ant-design:signal-filled" />
      แบบประเมินคุณภาพระบบประปาหมู่บ้าน
      <span class="font-deeporange weight-700 ml-3">ใช้แหล่งน้ำผิวดิน</span>
    </div>
    <!-- step progress bar -->
    <div class="row">
      <div class="col-3">
        <div
          class="step-progress-bar d-flex align-items-center"
          :class="{ active: currentSection === 1 }"
        >
          <div class="icon">
            <iconify icon="akar-icons:file" class="fontsize-h2" />
          </div>
          <span class="ml-3 fontsize-h4"
            ><span class="headline">ส่วนที่ 1</span> : ข้อมูลทั่วไป</span
          >
        </div>
      </div>
      <div class="col-6 d-flex align-items-center w-100">
        <div class="d-flex">
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>
        <div
          class="step-progress-bar d-flex align-items-center w-100 mx-4"
          :class="{ active: currentSection === 2 }"
        >
          <div class="icon">
            <iconify icon="bi:list-check" class="fontsize-h2" />
          </div>
          <span class="ml-3 fontsize-h4"
            ><span class="headline">ส่วนที่ 2</span> :
            การประเมินคุณภาพระบบประปาหมู่บ้าน</span
          >
        </div>
        <div class="d-flex">
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>
      </div>
      <div class="col-3">
        <div
          class="step-progress-bar d-flex align-items-center"
          :class="{ active: currentSection === 3 }"
        >
          <div class="icon">
            <iconify icon="ant-design:comment-outlined" class="fontsize-h2" />
          </div>
          <span class="ml-3 fontsize-h4"
            ><span class="headline">ส่วนที่ 3</span> : ข้อเสนอแนะเพิ่มเติม</span
          >
        </div>
      </div>
    </div>

    <!-- <div class="row my-3" v-if="currentSection === 0">
      <div class="col-12 text-center">
        <b-overlay :show="currentSection === 0">
          <div class="py-3 font-disgray" style="height: 300px"></div>
        </b-overlay>
      </div>
    </div> -->
    <b-overlay :show="isLoading" style="min-height: 300px">
      <!-- section#1  -->
      <section1
        v-show="currentSection === 1"
        :toReload="reloadSection"
        @updateCurSection="updateCurSection"
        @updateDetail="updateDetail"
        :formDetail="result1"
        :mode="mode"
      ></section1>
      <!-- section#2 -->
      <section2
        v-show="currentSection === 2"
        :toReload="reloadSection"
        @updateCurSection="updateCurSection"
        @updateQuestions="updateQuestions"
        :questions="result2"
        :mode="mode"
      ></section2>
      <!-- section#3 -->
      <section3
        v-show="currentSection === 3"
        :toReload="reloadSection"
        @updateCurSection="updateCurSection"
        @gotoSubmit="gotoSubmit"
        @updateContacts="updateContacts"
        :contacts="result3"
        :mode="mode"
      ></section3>
    </b-overlay>
  </div>
</template>

<script>
import Section1 from "./Section1.vue";
import Section2 from "./Section2.vue";
import Section3 from "./Section3.vue";
import { AssessmentService } from "@/services/main.service.js";
import { UserCardIdService } from "@/services/storage.service";

export default {
  name: "assessment-surface-form",
  components: {
    Section1,
    Section2,
    Section3,
  },
  data() {
    const breadcrumbs = [
      {
        to: "SurfaceForm",
        text: "แบบประเมินคุณภาพระบบประปาหมู่บ้าน",
      },
    ];
    return {
      breadcrumbs: breadcrumbs,
      rootBreadcrumb: {
        icon: null,
        text: "หน้าหลัก",
        to: "AssessmentList",
        active: false,
      },
      reloadSection: false,
      currentSection: 1,
      mode: null,
      result1: {},
      result2: [],
      result3: {},
      fileUploaded: {},
      surveyID: null,
      pwaTypeId: 1,
      isLoading: false,
      personId: null,
    };
  },
  async created() {
    window._surface = this;

    this.personId = UserCardIdService.getUserCardId();

    //check mode
    this.mode = this.$route.params?.mode;
    this.surveyID = this.$route.query?.surveyID || null;

    if (this.mode === "edit" && this.surveyID) {
      this.isLoading = true;
      //get form detail
      await this.getDetailQualitySurvey();

      this.currentSection = 1;
      this.isLoading = false;
      this.reloadSection = true;
    } else {
      this.currentSection = 1;
      setTimeout(() => {
        this.reloadSection = true;
      }, 100);
    }
  },
  methods: {
    goToAssessmentList() {
      this.$router.push({
        name: "AssessmentList",
      });
    },
    updateCurSection(current) {
      this.currentSection = current;
      if (this.mode === "edit") {
        this.$router.push({
          name: "SurfaceForm",
          query: { surveyID: this.surveyID, section: current },
        });
      } else {
        this.$router.push({
          name: "SurfaceForm",
          query: { section: current },
        });
      }
    },
    async getDetailQualitySurvey() {
      const param = {
        QualitySurveyID: this.surveyID,
      };
      await AssessmentService.getDetailQualitySurvey(param).then((res) => {
        this.pwaTypeId = res.pwaTypeId;

        this.result1 = this.groupSection1(res);

        this.result2 = this.groupSection2([
          ...res.qualitySurveyQuestionAnswers,
        ]);

        this.result3 = this.groupSection3({
          feedback: res.feedback,
          person: [...res.qualitySurveyContacts],
        });
      });
    },
    groupSection1(data) {
      const result = {
        ...data,
        qualitySurveyContacts: null,
        feedback: null,
        qualitySurveyQuestionAnswers: null,
      };

      return result;
    },
    groupSection2(data) {
      return data;
    },
    groupSection3(data) {
      return data;
    },
    updateDetail(data) {
      this.result1 = { ...data };
    },
    updateQuestions(data, fileUploaded = {}) {
      this.fileUploaded = fileUploaded || {};
      this.result2 = [...data];
    },
    updateContacts(data) {
      this.result3 = { ...data };
    },
    async gotoSubmit(val) {
      if (val) {
        const param = {
          PWATypeId: this.pwaTypeId,
          DataStep1_PWAType1: this.result1,
          Datastep2: this.result2,
          Datastep3: this.result3,
          SubmitByPerson: this.personId,
          ModifyByPerson: this.personId,
        };

        this.isLoading = true;

        // call service
        if (this.mode === "edit") {
          param["QualitySurveyId"] = this.surveyID;

          await AssessmentService.postAssessmentFormEdit(param)
            .then((res) => {
              this.isLoading = false;
              if (res.status) {
                //alert success
                this.alertSuccess("แก้ไขแบบประเมินสำเร็จ").then((res2) => {
                  //redirect to summary
                  this.$router.push({
                    name: "AssessmentSummary",
                    query: {
                      surveyID: res.actionId,
                    },
                  });
                });
              }
            })
            .catch((err) => {
              console.log("errr", err);
              this.isLoading = false;
              this.alertFail("ไม่สามารถแก้ไขแบบประเมิน");
            });

          //check มีการอัพโหลด
          if (this.fileUploaded.isUpload) {
            const paramFile = {
              QualitySurveyId: this.surveyID,
              SurveyQuestionId: this.fileUploaded.surveyQuestionId,
              File: this.fileUploaded.file,
            };
            await AssessmentService.postEditFile(paramFile)
              .then((res) => {})
              .catch((err) => {});
          }
        } else {
          await AssessmentService.postAssessmentFormAdd(param)
            .then(async (res) => {
              this.isLoading = false;

              const _surveyId = res.actionId;

              //check มีการอัพโหลด
              if (this.fileUploaded.isUpload) {
                const paramFile = {
                  QualitySurveyId: _surveyId,
                  SurveyQuestionId: this.fileUploaded.surveyQuestionId,
                  File: this.fileUploaded.file,
                };

                await AssessmentService.postAddFile(paramFile)
                  .then((resFile) => {})
                  .catch((errFile) => {});
              }

              //alert success
              this.alertSuccess("เพิ่มแบบประเมินสำเร็จ").then((res2) => {
                //redirect to summary
                this.$router.push({
                  name: "AssessmentSummary",
                  query: {
                    surveyID: _surveyId,
                  },
                });
              });
            })
            .catch((err) => {
              console.log("errr", err);
              this.isLoading = false;
              this.alertFail("ไม่สามารถเพิ่มแบบประเมิน");
            });
        }
      }
    },
  },
  computed: {},
};
</script>

<style></style>
